import OSS from 'ali-oss'
import Vue from 'vue'
import ENV from '../config/env'
import { isIos } from './ua'
import { copy } from './copy'
/* eslint-disable-next-line */
export function yasuo(content, size) {
  /* eslint-disable-next-line */
  const _this = this
  const base64 = content
  if (size / 1024 > 600) {
    // 图片大于1m   进行压缩
    const img = new Image()
    img.src = base64
    img.onload = () => {
      const that = img
      // 默认按比例压缩
      let w = that.width
      let h = that.height
      const scale = w / h

      if (w > h) {
        w = 1280
        h = w / scale
      } else {
        h = 1280
        w = h * scale
      }
      const quality = 0.7 // 默认图片质量为0.7
      // 生成canvas
      const canvas = document.createElement('canvas')
      canvas.width = w
      canvas.height = h
      const ctx = canvas.getContext('2d')
      ctx.drawImage(that, 0, 0, w, h)
      // quality值越小，所绘制出的图像越模糊
      return canvas.toDataURL('image/jpeg', quality)
    }
  } else {
    return base64
  }
}
export function dataURLtoBlob(dataurl) {
  const arr = dataurl.split(',')
  // 注意base64的最后面中括号和引号是不转译的
  /* eslint-disable-next-line */
  const _arr = arr[1].substring(0, arr[1].length - 2)
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(_arr)
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  /* eslint-disable-next-line */
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], {
    type: mime,
  })
}
export function getArg(name) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i')
  const r = window.location.search.substr(1).match(reg)
  if (r !== null) return decodeURI(r[2])
  return null
}
function add0(m) {
  return m < 10 ? `0${m}` : m
}
function timestamp() {
  const time = new Date()
  const y = time.getFullYear()
  const m = time.getMonth() + 1
  const d = time.getDate()
  const h = time.getHours()
  const mm = time.getMinutes()
  const s = time.getSeconds()

  return `${y}${add0(m)}${add0(d)}${add0(h)}${add0(mm)}${add0(s)}`
}
const config =
  ENV.mode === 'development'
    ? {
        region: 'oss-cn-beijing',
        accessKeyId: 'LTAIp9GJEoBrPGJF',
        accessKeySecret: 'kl5yxkctBpPgLmEJjhT2Z4srdwXL4O',
        bucket: 'test-rrx-img',
      }
    : {
        region: 'oss-cn-beijing',
        accessKeyId: 'LTAI4GJ2oNnYrU7Tzkx3P9NB',
        accessKeySecret: 'pzW8JqrYqNj0w3ZT4lgzfIkZEg79Di',
        bucketName: 'booktrading-img',
        oss_env: 'prod',
      }
export function upload(file) {
  const fileBase64 = yasuo(file.content, file.size)
  const client = new OSS(config)
  const name = timestamp()
  return client.put(`bt-image/${name}.jpg`, dataURLtoBlob(fileBase64))
}
/**
 *
 * 非父子组件
 * $on(),$emit()
 */
export function bus() {
  return new Vue()
}
/**
 * 复制链接
 * @param {*} url
 */
export function copyUrl(url) {
  if (isIos()) {
    const el = document.createElement('input')
    const btn = document.getElementById('btn')
    el.value = url
    el.style.opacity = '0'
    document.body.appendChild(el)
    const editable = el.contentEditable
    const { readOnly } = el
    el.contentEditable = true
    el.readOnly = false
    const range = document.createRange()
    range.selectNodeContents(el)
    const sel = window.getSelection()
    sel.removeAllRanges()
    sel.addRange(range)
    el.setSelectionRange(0, 999999)
    el.contentEditable = editable
    el.readOnly = readOnly
    const ret = document.execCommand('copy')
    el.blur()
    return ret
  }
  copy(url)
}

/**
 * 字节转换成
 */
export function sizeTostr(size) {
  size = Number(size)
  let data = ''
  if (size < 0.1 * 1024) {
    // 如果小于0.1KB转化成B
    data = `${size.toFixed(2)}B`
  } else if (size < 0.1 * 1024 * 1024) {
    // 如果小于0.1MB转化成KB
    data = `${(size / 1024).toFixed(2)}KB`
  } else if (size < 0.1 * 1024 * 1024 * 1024) {
    // 如果小于0.1GB转化成MB
    data = `${(size / (1024 * 1024)).toFixed(2)}MB`
  } else {
    // 其他转化成GB
    data = `${(size / (1024 * 1024 * 1024)).toFixed(2)}GB`
  }
  const sizestr = `${data}`
  // eslint-disable-next-line no-useless-escape
  const len = sizestr.indexOf('.')
  const dec = sizestr.substr(len + 1, 2)
  if (dec === '00') {
    // 当小数点后为00时 去掉小数部分
    return sizestr.substring(0, len) + sizestr.substr(len + 3, 2)
  }
  return sizestr
}

/** 生成随机字符串 */
export function getRandom(n) {
  let Num = ''
  let i
  for (i = 0; i < n; i++) {
    Num += Math.floor(Math.random() * 10)
  }
  return Num
}
