/**
 * 根据移动端和pc端的比例 转换签章在移动的端展示的大小
 * @param {number} wrapWidth - 容器的宽度
 * @param {number} value 签章的宽/高
 * @return {number} 转换后的签章的大小
 */
export function translateSealWidthAndHeight(wrapWidth, value) {
  // pc 端文件展示的宽
  const pcImgWidth = 595
  const sealScaleRate = 0.75
  return value * (wrapWidth / pcImgWidth) * sealScaleRate
}

/**
 * 根据移动端和pc端的比例 将移动端的签章大小转成真实的在盖章的时候的大小
 * @param {number} wrapWidth - 容器的宽度
 * @param {number} value 签章的宽/高
 * @return {number} 转换后的签章的真实大小
 */
export function reTranslateSealWidthAndHeight(wrapWidth, value) {
  // pc 端文件展示的宽
  const pcImgWidth = 595
  const sealScaleRate = 0.75

  const wrapRate = wrapWidth / pcImgWidth
  return value / sealScaleRate / wrapRate
}
