<template>
  <van-tabbar
    v-model="active"
    :border="false"
    class="active_tab"
    :fixed="false"
    :route="isZdd"
  >
    <template v-if="isZdd">
      <van-tabbar-item
        v-for="item in zddTabbars"
        :key="item.name"
        :to="{ name: item.name }"
        @change="onTabChange(item.name)"
        class="van-tabbar-item zdd"
      >
        <span :class="currentRouteName === item.name ? active : ''">{{
          item.title
        }}</span>
        <template slot="icon" slot-scope="props">
          <img :src="props.active ? item.active : item.normal" />
        </template>
      </van-tabbar-item>
    </template>
    <template v-else>
      <van-tabbar-item
        v-for="(item, index) in tabbars"
        :key="index"
        @click="tab(index, item.name)"
        :class="'van-tabbar-item' + index"
      >
        <span :class="currIndex === item.name ? active : ''">{{
          item.title
        }}</span>
        <template slot="icon">
          <img
            :src="currentRouteName === item.name ? item.active : item.normal"
          />
        </template>
      </van-tabbar-item>
    </template>
  </van-tabbar>
</template>

<script>
  import { isCurrentRoute } from '@/utils/isCurrentRoute'
  import { tabbars, zddTabbars } from './config'

  export default {
    name: 'tabbar',
    data() {
      return {
        zddTabbars: Object.freeze(zddTabbars),
        currIndex: 0,
        active: 0,
        tabbars: [],
        currentRouteName: undefined,
      }
    },
    computed: {
      systemInfo() {
        return this.$store.state.system
      },
      isZdd() {
        return this.$store.getters['system/isZdd']
      },
      isLogin() {
        return !!this.$store.getters.getUserInfo.userId
      },
      userInfo() {
        return this.$store.getters.getUserInfo
      },
      // 账号激活状态 需要激活 true 无需激活 false
      activationStatus() {
        return this.isLogin && !this.userInfo.telephone && !this.userInfo.email
      },
    },
    mounted() {
      this.tabbars = tabbars
    },
    methods: {
      tab(index, val) {
        this.currIndex = val
        this.$router.push(val)
      },
      onTabChange(routeName) {
        console.log('routeName', routeName)
        if (!isCurrentRoute({ name: routeName })) {
          this.$router.push({
            name: routeName,
          })
        }
      },
     
    },
    watch: {
      $route: {
        handler(to, from) {
          this.$nextTick(() => {
            this.currIndex = to.name
            this.currentRouteName = to.name
            // this.active === to.name
          })
        },
        immediate: true,
      },
    },
  }
</script>

<style lang="less" scoped>
  .van-tabbar {
    padding-bottom: @safe-area;
    padding-bottom: @safe-area-2;
  }
  .inputFile {
    position: absolute;
    left: 100vw;
  }
  .active_tab {
    box-shadow: 0px -6px 10px 0px rgba(230, 234, 237, 0.3);
    position: fixed;
    left: 0;
    bottom: 0;
  }
  .active_tab img {
    width: 20px;
    height: 20px;
  }
  .van-tabbar-item {
    &.zdd {
      .zdd-span {
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        color: #191f25;
      }
      /deep/ .van-tabbar-item__icon {
        margin-bottom: 7px;
        img {
          width: 17px;
          height: 18px;
        }
      }
      /deep/ .van-tabbar-item__text {
        font-size: 12px;
        font-weight: 500;
      }
      &.van-tabbar-item--active {
        color: @BLUE;
        font-weight: 500;
      }
    }
  }

  .van-tabbar-item--active {
    color: #111a34;
  }
  .add-button {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    position: absolute;
    left: 50%;
    margin-left: -30px;
    text-align: center;
    // box-shadow: 0px -6px 10px 0px rgba(230, 234, 237, 0.3);
    background: @WHITE;
    top: -13px;
    display: flex;
    align-items: center;
    justify-content: center;
    .add {
      width: 44px;
      height: 44px;
      border-radius: 22px;
      background: @BLUE;
      display: flex;
      align-items: center;
      justify-content: center;
      // box-shadow: 0px -6px 10px 0px rgba(230, 234, 237, 0.3);
      span {
        display: inline-flex;
        align-items: center;
        font-size: 38px;
        line-height: 24px;
        margin-top: -0.1rem;
        color: #fff;
      }
    }
  }
  // .van-tabbar-item0 {
  //   margin-right: 46px;
  // }
  // .van-tabbar-item1 {
  //   margin-left: 46px;
  // }
</style>
