export default [
  {
    path: '/mini/upload',
    name: 'MiniUpload',
    component: () => import('@/views/mini/Upload.vue'),
    meta: {
      title: '上传文件',
      navShow: false,
      keepAlive: false,
    },
  },
  {
    path: '/mini/uploadFile',
    name: 'MiniUploadFile',
    component: () => import('@/views/mini/UploadFile.vue'),
    meta: {
      title: '文件上传',
      navShow: false,
      keepAlive: false,
    },
  },
]
