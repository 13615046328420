import moment from 'moment'

/**
 * 日期时间格式化 (年月日 时分秒)
 *
 * @param timeStamp 时间戳需要乘1000，因为服务端返回的数据是秒
 * @return {string}
 */
export const datetimeFormat = timeStamp => {
  if (!timeStamp) return ''
  return moment(timeStamp).format('YYYY/MM/DD HH:mm:ss')
}

/**
 * 日期格式化 带单位 (2020年10月1日)
 * @param timeStamp - 时间戳需要乘1000，因为服务端返回的数据是秒
 * @return {string}
 */
export const dateFormatWithUnit = timeStamp => {
  if (!timeStamp) return ''
  return moment(timeStamp).format('YYYY年MM月DD日')
}
