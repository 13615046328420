import { getRandom } from '@/utils/index'

/** 发起流程Model */
export default {
  namespaced: true,
  state: {
    /** 签署方信息 */
    partnerInfo: [],
    templateData: {
      /** 是否允许接收方拒签文件0-否 1-是 */
      allowReturn: 1,
      /** 是否抄送 */
      assignSender: 0,
      /** 指定抄送人编号 */
      appointUid: [],
      /** 发起类型 0.单份发起 1.批量发起 */
      batchSign: 0,
      /** 是否顺序签署0-否 1-是 */
      signOrderFlag: 0,
      /** 描述 */
      descName: undefined,
      /** 文件列表 */
      fileList: [],
      /** 参考资料 */
      fileRelatedList: [],
      /** 0.草稿 1.发起 */
      launchSign: 0,
      launchTitlePrefix:'',
      /** 合同主表id */
      masterId: null,
      /** 是否允许签署方不实名签署 0-否 1-是 */
      noCreditSign: 0,
      /** 通知方式0-不限定 1-指定通知方式 2-短信通知接收方 3-不通知 */
      noticType: 1,
      /** 通知方式 */
      noticeWay: [0],
      /** 参与方列表 */
      partnerList: [],
      /** 指定抄送人编号 */
      sendUserList: [],
      /** 签署方式 0-不限定签署方式 1-指定签署方式 2-人脸验证 3-短信验证 4-密码验证 */
      signWay: 0,
      /** 模版来源: 0.无 1.市场 2.我的业务 */
      tplSource: null,
      /** 标题 */
      titleName: '',
      /** 签署有效期 */
      validDay: 30,
      /** 通知方式 */
      notic: '',
      /** 标题动态文字 */
      launchTitleContent: [],
      /** 发起签署步骤和注意事项等 */
      launchDesc: undefined,
      /** 业务发起人是否为全部 */
      creatorIsAll: 1,
      classifyId: undefined,
      numStepHistory: [],
      numStep: 2,
      step: 1,
    },
  },

  mutations: {
    setTemplateData(state, data) {
      state.templateData = Object.assign(state.templateData, data)
    },

    /** 添加签署方 */
    setPartnerInfo(state, data) {
      state.partnerInfo.push(data)
    },

    /** 更新签署方 */
    updatePartnerInfo(state, data) {
      state.partnerInfo = data
    },

    /** 删除签署方 */
    delPartnerInfo(state, guid) {
      state.partnerInfo = state.partnerInfo.filter(item => item.guid !== guid)
      state.partnerInfo.forEach((item, index) => {
        item.defaultName = `参与方${index + 1}`
      })
    },
  },
  actions: {},
  getters: {
    /** 判断选中的模版是否需要去填写变量: 需要填写-true */
    isVariable: state => {
      const tempVarArr = state.templateData.fileList.reduce((pre, cur) => {
        return pre.concat(cur.comVarParamList)
      }, [])
      // tempVarArr.some(item => !item.varValue)
      return tempVarArr.some(item => !item.varValue)
    },
  },
}
