// 签署相关的 enum

/**
 * 签名类型 0 默认 1 扫码签名 2 上传签名 3 艺术字签名 4 个人私印
 *
 * @type {{HANDWRITTEN_SIGNATURE: number, ARTISTIC_SIGNATURE: number, UPLOAD_SIGNATURE: number, DEFAULT: number}}
 */
export const USER_SIGNATURE_TYPE = {
  /**
   * 默认 - 0
   */
  DEFAULT: 0,
  /**
   * 扫码签名（手写签名）- 1
   */
  HANDWRITTEN_SIGNATURE: 1,
  /**
   * 上传签名 - 2
   */
  UPLOAD_SIGNATURE: 3,
  /**
   * 艺术字签名 - 3
   */
  ARTISTIC_SIGNATURE: 3,
  /**
   * 个人私印 - 4
   * !暂时没有
   */
}

/**
 * 发起步骤枚举
 *
 */
export const SAVE_DRAFT_STEP = {
  /**
   * 发起签署的表单页 - 1
   */
  LAUNCH_FORM_PAGE: 1,
  /**
   * 填写文件内容页面 - 2
   */
  SIGN_VAR_PAGE: 2,
  /**
   * 自己签署页面 - 3
   */
  SIGN_SELF: 3,
  /**
   * 指定签署位置页面 - 4
   */
  SIGN_POSITION: 4,
}
/**
 * 签章类型枚举
 * 0-个人签署 1-经办人签署 2-法人签署 3-组织签章 4.骑缝章
 *
 * @type {{COMPANY_SEAL: number, LEGAL_PERSON_SEAL: number, RIDING_SEAL: number, PERSONAL_SEAL: number, HANDLER_SEAL: number}}
 */
export const SIGN_SEAL_TYPE = {
  /**
   * 个人签署 - 0
   */
  PERSONAL_SEAL: 0,
  /**
   * 经办人签署 - 1
   */
  HANDLER_SEAL: 1,
  /**
   * 法人签署 - 2
   */
  LEGAL_PERSON_SEAL: 2,
  /**
   * 企业签章 - 3
   */
  COMPANY_SEAL: 3,
  /**
   * 骑缝章 - 4
   */
  RIDING_SEAL: 4,
}
