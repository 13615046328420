import Cookies from 'js-cookie'

/**
 * 判断是否登录过
 * @returns {boolean}
 */
export function hasCookies() {
  const userId = Cookies.get('userId')
  // has cookie userInfo
  return !!userId
}

/**
 * 重置cookie
 * @returns {Promise<void>}
 */
export async function resetCookies() {
  // console.log('resetCookies')
  // if (Cookies.get('userId')) {
  //   await Cookies.remove('userId')
  // }
  // if (Cookies.get('userInfo')) {
  //   await Cookies.remove('userInfo')
  // }
}
export function setCookie(key, value) {
  return Cookies.set(key, value)
}

export function getSession() {
  return {
    userId: Cookies.get('userId'),
    userInfo: Cookies.get('userInfo'),
  }
}
