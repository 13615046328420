import Vue from 'vue'
import Vuex from 'vuex'
import user from './module/user'
import sign from './module/sign'
import contractSign from './module/contractSign'
import launch from './module/launch'
import system from './module/system'
import publishModel from './module/publishModel'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    creditMsg: {},
    creditType: 2,
    signUrl: '',
    comSignUrl: '',
  },
  mutations: {
    setCreditMsg(state, payload) {
      state.creditMsg = payload
    },
    setSignUrl(state, payload) {
      state.signUrl = payload
    },
    setComSignUrl(state, payload) {
      state.comSignUrl = payload
    },
    setCreditType(state, payload) {
      state.creditType = payload
    },
  },
  actions: {},
  modules: {
    user,
    sign,
    contractSign,
    launch,
    system,
    publishModel,
  },
})
