import { get, post, qspost } from './axios'

const myApis = {
  /**
   * 获取用户信息
   * @param {*} param
   */
  getUserInfo(param) {
    return get('/user/my/getUserInfo', param)
  },
  /**
   * 获取用户信息
   * @param {*} param
   */
  getMyInfo(param) {
    return get('/user/my/getMyInfo', param)
  },
  // 切换当前身份
  updateCurrentIdentity(params) {
    return get('/user/my/updateCurrentIdentity', params)
  },

  /**
   * 设置登录密码
   * @param {*} param
   */
  setLoginPwd(param) {
    return get('/user/my/setLoginPwd', param)
  },
  /**
   * 设置用户头像
   * @param {*} param
   */
  updateUserHeadImg(param) {
    return post('/user/my/updateUserHeadImg', param)
  },
  /**
   * 设置默认签名
   * @param {*} param
   */
  setDefaultUserSign(param) {
    return get('/user/my/setDefaultUserSign', param)
  },
  /**
   * 删除签名
   * @param {*} param
   */
  delUserSign(param) {
    return get('/user/my/delUserSign', param)
  },

  /**
   * zdd 关闭首次引导选择身份
   *
   * @param param
   */
  closeHomePageGuideVersion(param) {
    return get('/user/my/closeHomePageGuideVersion', param)
  },

  /**
 * 给后端发送错误日志
 *
 * @param param
 */
  commonLog(param) {
    return get('/user/common/log', param)
  },
}

export default myApis
