import { isAndroid } from '@/utils/ua'

/**
 * 判断当前元素是否为 vant field 标签
 *
 * @param {Element} element
 */
function isVanField(element) {
  const classList = [...element.classList]
  return classList.some(className => className.startsWith('van-field'))
}

/**
 * 获取到焦点元素滚动到可视区
 *
 * @param  {Element} activeElement
 * @param {number} delay
 */
export function activeElementScrollIntoView(
  activeElement = document.activeElement,
  delay = 200
) {
  // Android 键盘弹起后操作
  const editable = activeElement.getAttribute('contenteditable')
  // 输入框、textarea或富文本获取焦点后没有将该元素滚动到可视区
  if (
    activeElement.tagName === 'INPUT' ||
    activeElement.tagName === 'TEXTAREA' ||
    isVanField(activeElement) ||
    editable === '' ||
    editable
  ) {
    setTimeout(() => {
      if (activeElement.scrollIntoViewIfNeeded) {
        activeElement.scrollIntoViewIfNeeded(true)
      }
      activeElement.scrollIntoView({ behavior: 'smooth' })
    }, delay)
  }
}

export function onKeyBoardPopup(inputElement) {
  if (isAndroid()) {
    let originHeight =
      document.documentElement.clientHeight || document.body.clientHeight

    window.addEventListener(
      'resize',
      () => {
        const resizeHeight =
          document.documentElement.clientHeight || document.body.clientHeight
        if (originHeight < resizeHeight) {
          // Android 键盘收起后操作
        } else {
          activeElementScrollIntoView(inputElement)
        }
        originHeight = resizeHeight
      },
      false
    )
  }
}

/**
 * 生成随机字符串
 * @param {number} length - 要生成的字符串的长度
 * @param {boolean} addTimestamp - 是否要在字符串末尾添加时间戳
 * @return {string} - 生成的随机字符串
 */
export function generateRandomKey(length = 8, addTimestamp = true) {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  if (addTimestamp) {
    result += new Date().getTime()
  }
  return result
}

export function isFileType(format) {
  return (
    format !== '.png' &&
    format !== '.jpg' &&
    format !== '.jpeg' &&
    format !== '.doc' &&
    format !== '.docx' &&
    format !== '.xls' &&
    format !== '.xlsx' &&
    format !== '.pdf' &&
    format !== '.ppt' &&
    format !== '.word'
  )
}

/**
 * 是否当前的变量值为空
 *
 * @param {any} value
 * @return boolean
 */
export const isEmptyVariableValue = value => {
  return value == null || value === ''
}

export const isApiURL = () => {
  const apiUrl = [
    '/signFilePage',
    '/addSignNamePage',
    '/signFileSuccess',
    '/signFilePreview',
    '/wxCallBack',
  ]
  console.log('window.location.pathname', window.location.pathname)
  return apiUrl.includes(window.location.pathname)
}
