export default [
  {
    path: '/zdd/createcom',
    name: 'CreateCom',
    component: () => import('@/views/zdd/CreateCom'),
    meta: {
      title: '创建企业',
      navShow: false,
      keepAlive: false,
    },
  },
  {
    path: '/zdd/home',
    name: 'ZddHome',
    component: () => import('@/views/zdd/ZddHome'),
    meta: {
      title: '职袋袋',
      navShow: true,
      keepAlive: true,
    },
  },
  {
    path: '/zdd/record',
    name: 'ZddRecord',
    component: () => import('@/views/zdd/ZddRecord'),
    meta: {
      title: '证书',
      navShow: true,
      keepAlive: true,
    },
  },
  {
    path: '/zdd/recordDetail',
    name: 'ZddRecordDetail',
    component: () => import('@/views/zdd/ZddRecordDetail'),
    meta: {
      title: '证书',
      navShow: false,
      keepAlive: false,
    },
  },
  {
    path: '/zdd/detail',
    name: 'ZddDetail',
    component: () => import('@/views/zdd/ZddDetail'),
    meta: {
      title: '档案详情',
      navShow: false,
      keepAlive: false,
    },
  },
  {
    path: '/zdd/share',
    name: 'ZddShare',
    component: () => import('@/views/zdd/ZddShare'),
    meta: {
      title: '分享',
      navShow: false,
      keepAlive: false,
    },
  },
  {
    path: '/zdd/invitepublic',
    name: 'ZddInvitePublic',
    component: () => import('@/views/zdd/ZddInvitePublic'),
    meta: {
      title: '职袋袋',
      navShow: false,
      keepAlive: false,
      whiteList: true,
    },
  },
]
