import { hasCookies } from '@/utils/cookies'

// const whiteList = [
//   'Login',
//   'Register',
//   'ForgetPassword',
//   'nameAndIdentitycard',
//   'inviteFirend',
//   'inviteExternalContact',
//   'notWxInner',
//   'addSignConfirm',
//   'addSignNoConfirm',
//   'inviteMember',
//   'SetPassword',
// ]
const hasUserInfo = store => !!store.state.user.userInfo.userId

async function getUserInfo(store) {
  await store.dispatch('fetchUserInfo')
  sessionStorage.setItem('store', JSON.stringify(store.state))
}
function permission({ router, store }) {
  return async (to, from, next) => {
    console.log('to: ',to);
    const obj = {
      url: to.path,
    }
    store.dispatch('uploadURL', obj)
    if (!to.meta.whiteList && !hasUserInfo(store)) {
      if (to.name === 'HomePage' || to.name === 'List' || to.name === 'My') {
        // console.log('to :',to);
        await next()
      }
      await getUserInfo(store)
    }
    if (to.name !== 'HomePage' || to.name !== 'List' || to.name !== 'My') {
      next()
    }

    // 如果没登录
    // if (!hasCookies()) {
    //   if (whiteList.includes(to.name)) {
    //     next();
    //   } else {
    //     next({ path: `/login?redirect=${from.path}`, replace: true });
    //   }
    // } else {
    //   // 如果登录了，判断是是否拉取用户信息了
    //   if (store.getters.isLogin) {
    //     next();
    //   } else {
    //     try {
    //       await store.dispatch('fetchUserInfo');
    //       next();
    //     } catch (error) {
    //       next({ path: `/login?redirect=${from.path}`, replace: true });
    //     }
    //   }
    // }
  }
}

export default permission
