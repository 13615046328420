import router from '@/router'
import passportApis from '@/api/passport'
import myApis from '@/api/my'
import sealApi from '@/api/seal'
import { resetCookies, hasCookies } from '@/utils/cookies'
import { isCurrentRoute } from '@/utils/isCurrentRoute'
import { CREDIT_STATUS, COM_CREDIT_STATUS } from '@/common/enum'
import { isWxMiniProgram } from '@/utils/ua'
import { navigateToMiniLogin } from '@/utils/mini'

export default {
  state: {
    userInfo: {
      authList: [],
      comId: undefined,
      comName: undefined,
      comCreditStatus: 0, // 企业认证
      createTime: undefined,
      creditStatus: undefined,
      creditTm: undefined,
      email: null,
      headImg: null,
      idCardNo: undefined,
      name: undefined,
      telephone: undefined,
      userId: undefined,
      isNewRegist: false,
      isSkipSetPassword: false,
      isCloseNoviceGuide: false, // zdd  是否完成选择身份
      leftUmber: 0,
    },
    myInfo: {
      companyInfoVoList: [], // 用户身份集合
      userSignList: [], // 个人签名集合
    },
    isComUser: false,
    addSign: false,
    scrollHeight: 0,
    loginCode: '',
  },
  mutations: {
    setLoginCode(state, payload) {
      state.loginCode = payload
    },
    setScrollHeight(state, payload) {
      state.scrollHeight = payload
    },
    setAddSign(state, payload) {
      state.addSign = payload
    },
    setUserInfo(state, payload) {
      state.userInfo = { ...state.userInfo, ...payload.userInfo }
    },
    setMyInfo(state, myInfo) {
      state.myInfo = myInfo
    },
    // 设置企业身份
    setComUser(state, payload) {
      state.isComUser = payload
    },
    // 重置userInfo
    resetUserInfo(state) {
      const defaultUserInfo = {
        authList: [],
        comId: undefined,
        comName: undefined,
        createTime: undefined,
        creditStatus: undefined,
        creditTm: undefined,
        email: null,
        headImg: null,
        idCardNo: undefined,
        name: undefined,
        telephone: undefined,
        userId: undefined,
        isNewRegist: false,
        isSkipSetPassword: false,
        isCloseNoviceGuide: false,
      }
      state.userInfo = { ...state.userInfo, ...defaultUserInfo }
    },
    // 重置myInfo
    resetMyInfo(state) {
      const defaultMyInfo = {
        companyInfoVoList: [], // 用户身份集合
        userSignList: [], // 个人签名集合
      }
      state.userInfo = { ...state.myInfo, ...defaultMyInfo }
    },
  },
  actions: {
    // 微信登录
    async init({ commit, dispatch }, payload) {
      try {
        const data = await passportApis.init(payload)
        if (data && data.userId) {
          await dispatch('fetchUserInfo')
        }
      } catch (error) {
        router.replace({ path: '/login' })
        console.error(error)
      }
    },
    // signlogin
    /* eslint-disable-next-line */
    async signlogin({ commit, dispatch }, payload) {
      try {
        if (window.sessionStorage.openId) {
          payload.openId = window.sessionStorage.openId
        }
        await passportApis.login(payload)
        await dispatch('fetchUserInfo')
        const res = await myApis.getUserInfo()
        commit('setUserInfo', res)
        await dispatch('getMyInfo') // 获取基本信息
      } catch (error) {
        return Promise.reject(error.msg)
      }
    },
    // 登录
    /* eslint-disable-next-line */
    async login({ commit, dispatch }, payload) {
      try {
        if (window.sessionStorage.openId) {
          payload.openId = window.sessionStorage.openId
        }
        const res = await passportApis.login(payload)
        if (res) {
          await dispatch('fetchUserInfo')
          // const { isNewRegist, isSkipSetPassword } = res
          // if (isNewRegist && !isSkipSetPassword) {
          //   if (!isCurrentRoute({ name: 'SetPassword' })) {
          //     if (window.sessionStorage.inviteMember) {
          //       router.push({
          //         path: '/setPassword',
          //         query: {
          //           redirect: '/inviteMemberHasLogin',
          //         },
          //       })
          //       return
          //     }
          //     await router.push({ name: 'SetPassword' })
          //     return
          //   }
          // }
        }
        return res
      } catch (error) {
        return Promise.reject(error.msg)
      }
    },
    // 分享注册登录
    /* eslint-disable-next-line */
    async shareLogin({ commit, dispatch }, payload) {
      try {
        if (window.sessionStorage.openId) {
          payload.loginModel.openId = window.sessionStorage.openId
        }
        const res = await passportApis.login(payload.loginModel)
        if (res) {
          await dispatch('fetchUserInfo')
        }
        return res
      } catch (error) {
        return Promise.reject(error.msg)
      }
    },
    // 登出
    async logout({ commit, dispatch }) {
      try {
        if (hasCookies()) {
          await passportApis.loginOut()
        }
      } catch (error) {
        console.error(error)
      } finally {
        await dispatch('resetSession')
        // if (isWxMiniProgram()) {
        //   navigateToMiniLogin()
        // } else {
        //   if (router.history.current.name !== 'Login') {
        //     await router.replace({ path: '/login' })
        //   }
        // }
      }
    },
    // 清空cookie 重置用户信息
    async resetSession({ commit }) {
      await resetCookies()
      // 登出 重置用户信息
      commit('resetUserInfo')
      commit('resetMyInfo')
    },

    // 拉取用户信息
    async fetchUserInfo({ state, commit, dispatch }) {
      try {
        if (hasCookies()) {
          const res = await myApis.getUserInfo()
          res.authList = res.authList ? res.authList : []
          const payload = {
            userInfo: res && res,
          }
          commit('setComUser', !!payload.userInfo.comId)
          commit('setUserInfo', payload)
          await dispatch('getMyInfo') // 获取基本信息
        }
        // await dispatch('load', { authList: state.userInfo.authList });
        // throw new Error('error')
      } catch (error) {
        await dispatch('logout')
        await dispatch('resetSession')
        // if (isWxMiniProgram()) {
        //   // 什么都不做就可以，logout 会跳转到小程序的登录
        // } else {
        //   if (router.history.current.name !== 'Login') {
        //     await router.replace({ path: '/login' })
        //   }
        // }

        console.error(error)
      }
    },
    // 切换当前身份
    async updateCurrentIdentity({ commit, dispatch }, comId) {
      await myApis.updateCurrentIdentity({ comId, isAfterEnd: true })
      await dispatch('fetchUserInfo')
    },
    // // 获取我的基本信息 用户身份 个人签名
    // async getMyInfo({ commit }) {
    //   const res = await myApis.getMyInfo()
    //   commit('setMyInfo', res)
    // },
    // 获取我的基本信息 用户身份 个人签名
    async getMyInfo({ commit, dispatch }) {
      let myInfo = {}
      const res = await myApis.getMyInfo()
      const userSignList = await dispatch('getUserSignatureInfo')
      userSignList.forEach(e => {
        e.sealImg = e.signatureImg
      })
      myInfo = {
        ...res,
        userSignList,
      }
      commit('setMyInfo', myInfo)
    },
    // 获取用户签名的其他信息 签名图片原图
    async getUserSignatureInfo({ commit }) {
      try {
        return await sealApi.getUserSignature()
      } catch (error) {
        console.error(error)
      }
    },
    // 页面埋点
    async uploadURL({ commit, dispatch }, payload) {
      try {
        const { url } = payload
        await passportApis.uploadURL({ url })
      } catch (error) {
        console.error(error)
      }
    },
  },
  getters: {
    loginCode: state => state.loginCode,
    getUserInfo(state) {
      return state.userInfo
    },
    isLogin(state) {
      return !!state.userInfo.userId
    },
    isNewRegist: state => state.userInfo.isNewRegist,
    isSkipSetPassword: state => state.userInfo.isSkipSetPassword,
    isComUser(state) {
      return !!state.isComUser
    },
    authList: state => state.userInfo.authList,
    hasRealName: state => state.userInfo.creditStatus === CREDIT_STATUS.SUCCESS,
    isComCredit: state =>
      state.userInfo.comCreditStatus === COM_CREDIT_STATUS.SUCCESS,
    isCredit: state => state.userInfo.creditStatus === 1,
    companyList: state => state.myInfo.companyInfoVoList,

    curUserCanUseSealList: state => {
      if (state.isComUser) {
        const { sealList } = state
        return sealList.filter(
          seal =>
            seal.sealOwnerList.findIndex(
              sealOwner => sealOwner.sealUserId === state.userInfo.userId
            ) >= 0
        )
      }
      return []
    },
  },
}
