import { ASSIGN_TYPE, SAVE_DRAFT_STEP } from '@/common/enum'
import { getCurLauncher } from '@/model/launch'
import {
  getMiddlePositionList,
  getStartPositionList,
} from '@/utils/handleSealPosition'
import cloneDeep from 'lodash.clonedeep'
import contractBusinessApi from '@/api/contractBusiness'
import sealApi from '@/api/seal'
import store from '@/store'
import Vue from 'vue'
import getSealRect from '@/utils/getSealRect'
import { SIGN_SEAL_TYPE } from '@/common/signEnum'

const isZdd = () => store.getters['system/isZdd']

export default {
  namespaced: true,
  state: {
    partnerId: null,
    signMsg: {},
    launch: {
      // 是否允许接收方拒签文件0-否 1-是
      allowReturn: 1,
      // 是否抄送
      assignSender: 0,
      // 指定抄送人编号
      appointUid: [],
      // 发起类型 0.单份发起 1.批量发起
      batchSign: 0,
      // 是否顺序签署0-否 1-是
      signOrderFlag: 0,
      // 描述
      descName: '',
      // 文件列表
      fileList: [],
      // 参考资料
      fileRelatedList: [],
      // 0.草稿 1.发起
      launchSign: null,
      // 合同主表id
      masterId: null,
      // 是否允许签署方不实名签署 0-否 1-是
      noCreditSign: 0,
      // 通知方式0-不通知 1-短信通知接收方
      noticeWay: 1,
      // 参与方列表
      partnerList: [],
      // 指定抄送人编号
      sendUserList: [],
      // 签署方式 0-不限定签署方式 1-指定签署方式 2-人脸验证 3-短信验证 4-密码验证
      signWay: 0,
      // 标题
      titleName: '',
      // 模板id
      tplId: null,
      // 模板来源 0.无 1.市场 2.我的业务
      tplSource: null,
      // 签署有效期
      validDay: 30,
      // 头部导航状态
      navStepNum: 1,
      // 标题动态文字
      launchTitleContent: [],
      // 发起签署步骤和注意事项等
      launchDesc: undefined,
      // 业务发起人是否为全部
      creatorIsAll: 1,
      classifyId: undefined,
      numStepHistory: [],
      // 发起的时候要先将签署的信息存储到这里
      partnerSignAddParams: [],
      // 发起时 将要审批的信息存储到这里
      approveParamList: [],
      signatureRequire: 0,
      numStep: 0, // 存草稿需要的 当前操作到第几步 0 默认是发起
      launchFromBusinessId: undefined,
      launchFromTemplateId: undefined,
      businessAuthParam: {
        // 当前业务的管理员列表(展示用)
        businessAdminList: [],
        // 发起人列表
        businessCreatorList: [],
      },
      logo: null, // logo

      variableSignCompleted: false, // 变量填写完毕
      batchSignCount: undefined, // 批量发起合同份数
    },
  },
  mutations: {
    setPartnerId(state, id) {
      state.partnerId = id
    },
    setSignMsg(state, signMsg) {
      state.signMsg = signMsg
    },
    clearSignMsg(state) {
      state.partnerId = null
      state.signMsg = {}
      console.log('clearSignMsg')
    },
    // 修改发起状态
    setLaunch(state, status = {}) {
      // 变更状态
      state.launch = {
        ...state.launch,
        ...status,
        partnerSignAddParams: status.partnerSignAddParams
          ? status.partnerSignAddParams
          : [],
      }
    },
    resetNumStep(state) {
      state.launch.numStepHistory.pop()
      state.launch.numStep =
        state.launch.numStepHistory[state.launch.numStepHistory.length - 1]
    },

    setSealList(state, payload) {
      state.sealList = payload
    },

    addSeal(state, payload) {
      const { userId } = store.getters.getUserInfo
      const {
        fileIndex,
        partnerIndex,
        x,
        y,
        sealId,
        signType,
        sealKey,
        sealImg,
        page,
        id,
        dateFlag,
        width,
        height,
      } = payload

      // 找到当前的项
      const curPartnerSignIndex = getCurPartnerSignIndex(
        state.launch.partnerSignAddParams,
        fileIndex,
        partnerIndex
      )
      if (curPartnerSignIndex >= 0) {
        state.launch.partnerSignAddParams[curPartnerSignIndex].signList.push({
          x,
          y,
          id,
          sealId,
          signType,
          sealKey,
          sealImg,
          page,
          dateFlag,
          width,
          height,
          userId: signType === 0 ? userId : undefined,
        })
      } else {
        state.launch.partnerSignAddParams.push({
          fileIndex,
          partnerIndex,
          signList: [
            {
              x,
              y,
              sealId,
              signType,
              sealKey,
              sealImg,
              page,
              id,
              dateFlag,
              width,
              height,
              userId: signType === 0 ? userId : undefined,
            },
          ],
        })
      }
      const curFileIndex = getCurFileIndexByFileIndex(
        state.launch.fileList,
        fileIndex
      )
      if (curFileIndex >= 0) {
        if (!state.launch.fileList[curFileIndex].signData) {
          Vue.set(state.launch.fileList[curFileIndex], 'signData', {
            signList: [],
          })
        } else if (!state.launch.fileList[curFileIndex].signData.signList) {
          Vue.set(state.launch.fileList[curFileIndex].signData, 'signList', [])
        }
        state.launch.fileList[curFileIndex].signData.signList.push({
          x,
          y,
          sealId,
          signType,
          sealKey,
          sealImg,
          page,
          dateFlag,
          width,
          height,
          userId: signType === 0 ? userId : undefined,
        })
      }
    },

    /**
     * 重置签署状态
     *
     * @param state
     */
    resetSignState(state) {
      const defaultModel = {
        // 是否允许接收方拒签文件0-否 1-是
        allowReturn: 1,
        // 是否抄送
        assignSender: 0,
        // 指定抄送人编号
        appointUid: [],
        // 发起类型 0.单份发起 1.批量发起
        batchSign: 0,
        // 是否顺序签署0-否 1-是
        signOrderFlag: 0,
        // 描述
        descName: undefined,
        // 文件列表
        fileList: [],
        // 参考资料
        fileRelatedList: [],
        // 0.草稿 1.发起
        launchSign: null,
        // 合同主表id
        masterId: null,
        // 是否允许签署方不实名签署 0-否 1-是
        noCreditSign: 0,
        // 通知方式0-不限定 1-指定通知方式 2-短信通知接收方 3-不通知
        noticeWay: 1,
        // 参与方列表
        partnerList: [],
        // 指定抄送人编号
        sendUserList: [],
        // 签署方式 0-不限定签署方式 1-指定签署方式 2-人脸验证 3-短信验证 4-密码验证
        signWay: 0,
        // 标题
        titleName: undefined,
        // 模板id
        tplId: undefined,
        // 模板来源 0.无 1.市场 2.我的业务
        tplSource: null,
        numStepHistory: [],
        // 签署有效期
        validDay: 30,
        // 头部导航状态
        navStepNum: 1,
        // 标题动态文字
        launchTitleContent: [],
        // 发起签署步骤和注意事项等
        launchDesc: undefined,
        signatureRequire: 0,
        partnerSignAddParams: [],
        approveParamList: [],
        numStep: 0, // 存草稿需要的 当前操作到第几步 0 默认是发起
        launchFromBusinessId: undefined,
        launchFromTemplateId: undefined,
        simpleLaunch: 0,
        variableSignCompleted: false,
        batchSignCount: undefined,
      }
      state.launch = {
        ...state.launch,
        ...defaultModel,
      }
    },
  },
  actions: {
    // 获取草稿详情
    async getContractDraft({ commit, dispatch }, params = {}) {
      const { masterId, setDefaultSign } = params
      let data = await contractBusinessApi.draft({ masterId })
      const list = await sealApi.getSealListByStatus()
      commit('setSealList', list)
      // 生成随机数
      const getRandom = length => {
        let Num = ''
        let i
        for (i = 0; i < length; i++) {
          Num += Math.floor(Math.random() * 10)
        }
        return Num
      }
      data.fileRelatedList = data.fileRelatedList || []
      data.messageType = data.messageType || []

      data.partnerList.forEach((partner, index) => {
        partner.myNumber = Math.random()
        partner.guid = getRandom(10)
        partner.noticeWay = partner.noticeWay || []

        if (partner.partnerRole === 0) {
          // 内部参与方
          if (
            partner.assignType === ASSIGN_TYPE.LEGAL_PERSON ||
            partner.assignType === ASSIGN_TYPE.LAUNCHER
          ) {
            // 法人 发起人 assignId 是不填的
          } else {
            // 设置一个属性标记是否能编辑
            // 这个值只在获取数据初始化的时候调用
            Vue.set(data.partnerList, index, {
              ...partner,
              assignNameCanEdit: !partner.assignName,
              assignIdCanEdit: !partner.assignId,
              assignIdListCanEdit: !(
                partner.assignIdList && partner.assignIdList.length
              ),
            })
          }
        } else {
          // 如果是外部组织 或者 外部个人的情况，需要判断 comId comName handlerName handlerTel
          // const keyList = [
          //   'comId',
          //   'comName',
          //   'handlerName',
          //   'handlerTel',
          //   'customName',
          // ]
          Vue.set(data.partnerList, index, {
            ...partner,
            comIdCanEdit: !partner.comId,
            comNameCanEdit: !partner.comName,
            handlerNameCanEdit: !partner.handlerName,
            handleTelCanEdit: !partner.handlerTel,
            customNameCanEdit: !partner.customName,
          })
        }
      })

      if (data.partnerSignAddParams) {
        const paramsList = data.partnerSignAddParams.map(async item => {
          item.signList = await getStartPositionList(cloneDeep(item.signList))
          return { ...item }
        })
        data.partnerSignAddParams = await Promise.all(paramsList)
      }
      // 处理文件签章
      data = processFileSignList(data)
      commit('setLaunch', data)
      if (setDefaultSign) {
        commit('setSealDefault')
      }
      return data
    },
    // 自动落章逻辑
    async setSealDefault(state, payload) {
      try {
        let currentObj
        if (isZdd()) {
          // zdd 默认发起方就是第一个参与方
          ;[currentObj] = state.launch.partnerList
        } else {
          currentObj = getCurLauncher(
            state.launch.partnerList,
            state.launch.signOrderFlag
          )
        }
        if (!currentObj) return

        const that = this
        const { userSignList } = store.state.user.myInfo
        const temp = state.sealList.map(x => {
          return {
            sealImg: x.sealImg,
            signIdE: x.sealId,
            signatureImg: x.sealImg,
            signatureKey: x.sealUrl,
            defaultSeal: x.defaultSeal,
          }
        })
        const sealAllList = [...userSignList, ...temp]
        let obj = {}

        // 参与方已经签署的签章的位置
        const partnerSignList = state.launch.partnerSignAddParams.reduce(
          (memo, cur) => {
            memo = [...memo, ...cur.signList]
            return memo
          },
          []
        )

        // 筛选出是指定位置的印章
        const hasPositionPartnerSignList = partnerSignList.filter(
          sign => sign.id
        )

        state.launch.fileList.forEach(fileItem => {
          fileItem.signPositionList.forEach(signPosition => {
            if (currentObj.partnerIndex !== signPosition.partnerIndex) return
            const noSignSealPosition = signPosition.positionList.filter(
              position => {
                return !hasPositionPartnerSignList.find(
                  sign => sign.id === position.id
                )
              }
            )
            signPosition.positionList.forEach(async position => {
              if (isZdd() && (!position.sealId || position.sealId === '3Q')) {
                // zdd 当前的位置没有指定 sealId
                // 直接用默认章
                const defaultSeal = temp.find(seal => seal.defaultSeal === 1)
                if (defaultSeal && defaultSeal.signIdE) {
                  const {
                    sealImg,
                    signIdE,
                    signatureImg,
                    signatureKey,
                  } = defaultSeal

                  position.sealKey = signatureKey
                  position.sealImg = signatureImg
                  position.sealUrl = signatureKey
                  position.sealId = signIdE

                  let h = 93
                  let w = 93
                  if (signatureImg) {
                    const { width, height } = await getSealRect(signatureImg)
                    w = width > 225 ? 225 : width
                    h = width > 225 ? (225 / width) * height : height
                  }

                  that.commit('sign/addSeal', {
                    partnerIndex: signPosition.partnerIndex,
                    fileIndex: fileItem.fileIndex,
                    signType: position.signType,
                    sealKey: signatureKey,
                    sealImg: signatureImg || signatureKey,
                    sealId: signIdE,
                    page: position.page,
                    id: position.id,
                    x: position.x,
                    y: position.y,
                    dateFlag: position.dateFlag,
                    width: w,
                    height: h,
                  })
                }
                return
              }
              if (!position.sealId) return
              obj = sealAllList.find(e => e.signIdE === position.sealId)
              if (obj) {
                let h = 93
                let w = 93
                if (obj.signatureImg) {
                  const { width, height } = await getSealRect(obj.signatureImg)
                  w = width > 225 ? 225 : width
                  h = width > 225 ? (225 / width) * height : height
                }
                position.sealKey = obj.signatureKey
                position.sealImg = obj.signatureImg
                position.sealUrl = obj.signatureKey
                position.sealId = obj.signIdE
                console.log('自动落章', obj)
                if (!position.signType === SIGN_SEAL_TYPE.PERSONAL_SEAL) {
                  position.width = w
                  position.height = h
                }
                if (
                  noSignSealPosition.find(
                    signSeal => signSeal.id === position.id
                  )
                ) {
                  that.commit('sign/addSeal', {
                    partnerIndex: signPosition.partnerIndex,
                    fileIndex: fileItem.fileIndex,
                    signType: position.signType,
                    sealKey: obj.signatureKey,
                    sealImg: obj.signatureImg || obj.signatureKey,
                    sealId: obj.signIdE,
                    page: position.page,
                    id: position.id,
                    x: position.x,
                    y: position.y,
                    dateFlag: position.dateFlag,
                    width:
                      position.signType === SIGN_SEAL_TYPE.PERSONAL_SEAL
                        ? position.width
                        : w,
                    height:
                      position.signType === SIGN_SEAL_TYPE.PERSONAL_SEAL
                        ? position.height
                        : h,
                  })
                }
              }
            })
          })
        })
      } catch (error) {
        console.error(error)
      }
    },
    async saveWithStatus({ commit }, payload) {
      let { launchInfo, vm, saveCallback } = payload
      let count = 1
      let timer = null
      let confirmModal = null
      if (vm) {
        vm.$on('hook:beforeDestroy', () => {
          if (confirmModal) {
            confirmModal.handleClose()
          }
        })
      }

      try {
        if (
          launchInfo.batchSign &&
          launchInfo.numStep !== SAVE_DRAFT_STEP.LAUNCH_FORM_PAGE
        ) {
          let str
          if (launchInfo.launchSign) {
            // 发起了
            str =
              '由于批量发起份数过多，大概需要等待1-5分钟，您可以退出进行其他操作，不影响本次发起，后续可以在「文件」找到发起的文件。'
          } else {
            str =
              '由于批量发起份数过多，大概需要等待10秒，您也可以暂时退出当前页面，不影响本次发起，后续可以在「文件-草稿」找到该文件继续操作。'
          }
          timer = setInterval(() => {
            if (count >= 3) {
              clearInterval(timer)
              count = 1
              timer = null
              confirmModal = vm.$alert({
                title: '提示',
                content: str,
              })
            }
            count++
          }, 1000)
        }

        const launcher = getCurLauncher(
          launchInfo.partnerList,
          launchInfo.signOrderFlag
        )
        if (launcher && launcher.partnerIndex) {
          launchInfo = {
            ...launchInfo,
            partnerIndex: launcher.partnerIndex,
          }
        }

        // 处理签章的位置，左上角的位置换算成左下角
        if (
          launchInfo.partnerSignAddParams &&
          launchInfo.partnerSignAddParams.length
        ) {
          // 签署的页面处理 签章位置
          const list = launchInfo.partnerSignAddParams.map(async item => {
            item.signList = await getMiddlePositionList(
              cloneDeep(item.signList)
            )
            return { ...item }
          })
          launchInfo.partnerSignAddParams = await Promise.all(list)
        }

        // 处理历史记录
        launchInfo = processNumStepHistory(launchInfo)
        const res = await contractBusinessApi.saveWithStatus(launchInfo)
        if (res) {
          const { data, msg, status } = res
          if (status === 200) {
            // 发起成功，判断是否为批量发起的
            // if (launchInfo.launchSign && !launchInfo.batchSign) {
            //   vm.$showLaunchSuccessConfirmModal({
            //     launchInfo: data,
            //     title: '发起成功',
            //   })
            // }

            return data
          }
          // 发起接口报错，则要重置刚才修改的步骤
          commit('resetNumStep')

          if (
            status === 201 &&
            (msg === '公司账户份数不足' || msg === '个人账户份数不足')
          ) {
            vm.$showChargeDialog()
            saveCallback && (await saveCallback())
          } else {
            vm.$toast({
              message: msg,
              type: 'fail',
            })
          }
          // return res
        }
      } catch (error) {
        console.error(error)
      } finally {
        clearInterval(timer)
        timer = null
      }
    },
  },
}

/**
 * 处理保存草稿的时候的 操作记录，每一步都要记录
 *
 * @param launchInfo
 * @return {*}
 */
const processNumStepHistory = launchInfo => {
  // 当前在哪一步
  const { numStep } = launchInfo
  const value = String(numStep)
  // 处理操作历史记录 numStepHistory
  if (launchInfo.numStepHistory && launchInfo.numStepHistory.length) {
    //
  } else {
    // 如果还没有存储过操作步骤历史记录，则默认将 发起页 添加进去
    launchInfo.numStepHistory = [String(SAVE_DRAFT_STEP.LAUNCH_FORM_PAGE)]
  }
  // 如果当前历史记录中已经存储了当前步骤了，则不再存储
  // 因为存储草稿的时候是直接将当前页存进去，需要做判断
  // 因为不会出现一个步骤操作多次的情况
  if (!launchInfo.numStepHistory.includes(value)) {
    launchInfo.numStepHistory.push(value)
  }
  return launchInfo
}

/**
 * 处理文件签章列表
 *
 * 需要将 partnerSignAddParams 中的所有的文件的签章挨个筛选出来，放到 fileList 中
 */
const processFileSignList = model => {
  model = cloneDeep(model)
  const { partnerSignAddParams, fileList } = model
  const fileToSignListMap = new Map()
  if (partnerSignAddParams && partnerSignAddParams.length) {
    partnerSignAddParams.forEach(partnerSign => {
      const { fileIndex, signList } = partnerSign
      let curFile = fileToSignListMap.get(fileIndex)
      if (!curFile) {
        fileToSignListMap.set(fileIndex, (curFile = new Set()))
      }
      signList.forEach(sign => {
        curFile.add(sign)
      })
    })
    fileToSignListMap.forEach((signList, fileIndex) => {
      fileList.forEach(file => {
        if (file.fileIndex === fileIndex) {
          if (!file.signData) {
            file.signData = {}
          }
          file.signData.signList = [...signList]
        }
      })
    })
  }

  return model
}

/**
 * 发起的时候 找到当前的变量存储的位置
 * 发起方签署的时候 值 都会存到  partnerSignAddParams 中
 * @param list
 * @param fileIndex
 * @param partnerIndex
 * @return {*}
 */
const getCurPartnerSignIndex = (list, fileIndex, partnerIndex) => {
  return list?.findIndex(
    item => item.fileIndex === fileIndex && item.partnerIndex === partnerIndex
  )
}

/**
 * 根据文件的fileIndex 获取当前文件在 fileList 中的index
 * @param fileList - 文件列表
 * @param fileIndex - 文件的 fileIndex 属性
 */
const getCurFileIndexByFileIndex = (fileList, fileIndex) => {
  return fileList.findIndex(file => file.fileIndex === fileIndex)
}
