import { SYSTEM_ID } from '@/common/enum'

export default {
  namespaced: true,
  state: {
    systemId: 0, // 0易云章 1题点 2职签
    tooltipBg: '',
    TaxpayerIdentificationNumber: '',
    qrcode: '',
    logo2: '',
    logo1: '',
    logo: '',
    loginBg2: '',
    loginBg1: '',
    hostPC: '',
    hostH5: '',
    headerBgColor: '',
    Copyright: '',
    CompanyTel: '',
    companyName: '',
    CompanyAddress: '',
    CompanyAccountBank: '',
    CompanyAccount: '',
    company: '',
    color: '',
  },
  mutations: {
    // 设置系统信息
    setSystemInfo(state, payload) {
      const {
        systemId,
        tooltipBg,
        TaxpayerIdentificationNumber,
        qrcode,
        logo2,
        logo1,
        logo,
        loginBg2,
        loginBg1,
        hostPC,
        hostH5,
        headerBgColor,
        Copyright,
        CompanyTel,
        companyName,
        CompanyAddress,
        CompanyAccountBank,
        CompanyAccount,
        company,
        color,
      } = payload
      state.systemId = systemId
      state.tooltipBg = tooltipBg
      state.TaxpayerIdentificationNumber = TaxpayerIdentificationNumber
      state.qrcode = qrcode
      state.logo2 = logo2
      state.logo1 = logo1
      state.logo = logo
      state.loginBg2 = loginBg2
      state.loginBg1 = loginBg1
      state.hostPC = hostPC
      state.hostH5 = hostH5
      state.headerBgColor = headerBgColor
      state.Copyright = Copyright
      state.CompanyTel = CompanyTel
      state.companyName = companyName
      state.CompanyAddress = CompanyAddress
      state.CompanyAccountBank = CompanyAccountBank
      state.CompanyAccount = CompanyAccount
      state.company = company
      state.color = color
    },
  },
  actions: {},
  getters: {
    systemInfo: state => {
      return state
    },
    isZdd: state => {
      return state.systemId === SYSTEM_ID.ZDD
    },
  },
}
