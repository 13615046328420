/**
 * 系统枚举
 *
 * @type {{TD: number, YYZ: number, ZDD: number}}
 */
export const SYSTEM_ID = {
  YYZ: 0,
  TD: 1,
  ZQ: 2,
  ZDD: 3,
}

/**
 * 认证状态
 *
 * @type {{SUCCESS: number, FAILED: number, NOT_CREDIT: number}}
 */
export const CREDIT_STATUS = {
  /**
   * 未认证 - 0
   */
  NOT_CREDIT: 0,
  /**
   * 认证成功 - 1
   */
  SUCCESS: 1,
  /**
   * 认证失败 - 2
   */
  FAILED: 2,
}

/**
 * 认证状态：0.认证中 1.认证成功 2.审核拒绝 3.审核中 4.未认证
 *
 * @type {{SUCCESS: number, REJECT: number, CREDITING: number, CHECKING: number, NOT_CREDIT: number}}
 */
export const COM_CREDIT_STATUS = {
  /**
   * 认证中 - 0
   */
  CREDITING: 0,
  /**
   * 认证成功 - 1
   */
  SUCCESS: 1,
  /**
   * 审核拒绝 - 2
   */
  REJECT: 2,
  /**
   * 审核中 - 3
   */
  CHECKING: 3,
  /**
   * 未认证 - 4
   */
  NOT_CREDIT: 4,
}

/**
 * 发起签署或者创建应用中 添加的文件的类型
 *
 * @readonly
 * @enum
 * @type {{TEMPLATE_APP: (TEMPLATE_SOURCE|number), NORMAL_FILE: (TEMPLATE_SOURCE|number), LOCAL_UPLOAD: (TEMPLATE_SOURCE|number), MAKE_ONLINE: (TEMPLATE_SOURCE|number)}}
 */
export const TEMPLATE_SOURCE = {
  LOCAL_UPLOAD: 0,
  NORMAL_FILE: 1,
  TEMPLATE_APP: 2,
  MAKE_ONLINE: 3,
}

/**
 * 参与方身份
 *
 * @readonly
 * @enum
 * @type {{PARTNER_PERSONAL: number, LAUNCHER: number, PARTNER_COM: number}}
 */
export const PARTNER_ROLE = {
  /**
   * 发起方 - 0
   */
  LAUNCHER: 0,
  /**
   * 参与方(外部个人) - 1
   */
  PARTNER_PERSONAL: 1,
  /**
   *参与方(外部企业) - 2
   */
  PARTNER_COM: 2,
}

export const SAVE_DRAFT_STEP = {
  /**
   * 发起签署的表单页 - 1
   */
  LAUNCH_FORM_PAGE: 1,
  /**
   * 填写文件内容页面 - 2
   */
  SIGN_VAR_PAGE: 2,
  /**
   * 自己签署页面 - 3
   */
  SIGN_SELF: 3,
  /**
   * 指定签署位置页面 - 4
   */
  SIGN_POSITION: 4,
}

/**
 * 对指定的操作指定 操作人 或者章
 *
 * @type {{APPOINT_SEAL: number, LEGAL_PERSON: number, LAUNCHER: number, RELATIONSHIP_ROLE: number, APPOINT_COM_ROLE: number, APPOINT_PERSON: number}}
 */
export const ASSIGN_TYPE = {
  /**
   * 指定人 - 0
   */
  APPOINT_PERSON: 0,
  /**
   * 指定章 - 1
   */
  APPOINT_SEAL: 1,
  /**
   * 选企业角色 - 2
   */
  APPOINT_COM_ROLE: 2,
  /**
   * 选关系角色 - 3
   */
  RELATIONSHIP_ROLE: 3,
  /**
   * 法人 - 4
   */
  LEGAL_PERSON: 4,
  /**
   * 发起人本人 - 5
   */
  LAUNCHER: 5,
}
