<template>
  <div id="app" :class="isPc ? 'appPc' : ''">
    <div class="appContent">
      <keep-alive>
        <router-view
          v-if="$route.meta.keepAlive"
          class="router-transition"
          :isPopup="isPopup"
        ></router-view>
      </keep-alive>
      <router-view
        v-if="!$route.meta.keepAlive"
        class="router-transition"
        :isPopup="isPopup"
      ></router-view>
    </div>
    <!-- <transition :name="transitionName">
      <router-view class="router-transition" :isPopup="isPopup"></router-view>
    </transition> -->
    <FooterNav v-show="$route.meta.navShow" />
  </div>
</template>
<script>
  import { hasCookies, getSession } from '@/utils/cookies'
  import { Toast } from 'vant'
  import Apifiles from '@/api/files'
  import myApis from '@/api/my'
  // import Header from './components/Header.vue'
  import wechatApi from '@/api/user/wechat'
  import FooterNav from './components/FooterNav/index.vue'
  import { isWxMiniProgram } from './utils/ua'

  export default {
    components: {
      FooterNav,
      // Header,
    },
    data() {
      return {
        isPopup: false,
        isPc: false,
        transitionName: 'slide-right',
      }
    },
    computed: {
      systemInfo() {
        return this.$store.state.system
      },
    },
    async created() {
      await this.getWxConfig()
      // hack
      // 修复 发送的短信中的地址是错误的问题
      // 短信中发送的 地址是 //files/detail
      // 要将其重定向到 SignDetail 页面
      const { path, query } = this.$route
      if (path === '//files/detail') {
        this.$router.replace({
          name: 'SignDetail',
          query,
        })
      }

      Toast.setDefaultOptions({ duration: 3000 })
      Toast.allowMultiple()
      this.setPopup()

      // this.getSystem()
    },
    watch: {
      $route(to, from) {
        const { isBack } = this.$router
        if (isBack) {
          this.transitionName = 'slide-right'
        } else {
          this.transitionName = 'slide-left'
        }
        this.$router.isBack = false
      },
    },
    methods: {
      setPopup() {
        const isAndroid =
          navigator.userAgent.indexOf('Android') > -1 ||
          navigator.userAgent.indexOf('Adr') > -1
        const u = navigator.userAgent
        const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
        this.originalHeight =
          document.documentElement.clientHeight || document.body.clientHeight
        if (!isiOS && !isAndroid) {
          document.querySelector('html').setAttribute('class', 'setHtml')
          this.isPc = true
        } else {
          document.querySelector('html').className = ''
          this.isPc = false
        }
        document.body.onresize = () => {
          if (document.documentElement.clientWidth > 600) {
            document.querySelector('html').setAttribute('class', 'setHtml')
            this.isPc = true
          } else {
            document.querySelector('html').className = ''
            this.isPc = false
          }
          if (isiOS) {
            return
          }
          if (!isAndroid) {
            return
          }
          const resizeHeight =
            document.documentElement.clientHeight || document.body.clientHeight
          if (resizeHeight - 0 < this.originalHeight - 0) {
            this.$nextTick(() => {
              this.isPopup = true
            })
          } else {
            this.$nextTick(() => {
              this.isPopup = false
            })
          }
        }
      },

      async getWxConfig() {
        try {
          const wxConfig = await wechatApi.signURL()
          this.initWxConfig(wxConfig)
        } catch (error) {
          console.error(error)
        }
      },
      initWxConfig(wxConfig) {
        const { appId, timestamp, nonceStr, signature } = wxConfig
        window.wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId, // 必填，公众号的唯一标识
          timestamp, // 必填，生成签名的时间戳
          nonceStr, // 必填，生成签名的随机串
          signature, // 必填，签名
          jsApiList: [
            'updateAppMessageShareData',
            'onMenuShareAppMessage',
            'updateTimelineShareData',
          ], // 必填，需要使用的JS接口列表
        })
        const url = `${this.systemInfo.hostH5}zdd/home`
        this.initWxShare(url)
        this.postMessageToMiniProgram()
      },
      initWxShare(url) {
        window.wx.ready(() => {
          console.log('wx ready')
          window.wx.checkJsApi({
            jsApiList: ['updateAppMessageShareData', 'onMenuShareAppMessage'],
            success: res => {
              console.log('check js api', res)
            },
          })
          // 需在用户可能点击分享按钮前就先调用
          window.wx.updateAppMessageShareData({
            title: `邀请你使用「职袋袋」，一起守护职场信用吧~`, // 分享标题
            desc: `在职袋袋查档、建档、发高光证书，快速识别人才、激发团队活力`, // 分享描述
            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: this.systemInfo.logo2,
            // 'https://h5.ecloudsign.com/img/icons/android-chrome-192x192.png', // 分享图标
            success() {
              // 设置成功
              console.log('share success')
            },
            cancel() {
              console.log('share cancel')
            },
            fail(res) {
              console.log('share fail', res)
            },
          })

          // 即将废弃
          window.wx.onMenuShareAppMessage({
            title: `邀请你使用「职袋袋」，一起守护职场信用吧~`, // 分享标题
            desc: `在职袋袋查档、建档、发高光证书，快速识别人才、激发团队活力`, // 分享描述
            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: this.systemInfo.logo2,
            type: 'link', // 分享类型,music、video或link，不填默认为link
            dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
            success() {
              // 用户点击了分享后执行的回调函数
              console.log('share success 废弃接口')
            },
            cancel() {
              console.log('share cancel 废弃接口')
            },
            fail(res) {
              console.log('share fail 废弃接口')
            },
          })
        })
      },

      postMessageToMiniProgram() {
        // 判断是否是在 小程序环境下
        console.log('小程序环境', isWxMiniProgram())
        if (isWxMiniProgram()) {
          window.wx.ready(() => {
            const { userId, userInfo } = getSession()
            if (userId) {
              // 在小程序环境下需要向小程序通知当前的 cookie 信息
              window.wx.miniProgram.postMessage({
                data: {
                  type: 'setSession',
                  userId,
                  userInfo,
                },
              })
            }
          })
        }
      },
    },
  }
</script>
<style lang="less">
  #app {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    // min-height: 100%;
    // height: -webkit-fill-available;
    // height: 100%;
    // overflow-x: hidden;
    // overflow-y: auto;
    // overflow: hidden;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    position: relative;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
  }
  .appPc {
    width: 375px;
    height: 600px !important;
    margin: 30px auto;
    background-color: #fff;
  }
  .appContent {
    // flex: 1;
    // overflow-y: auto;
  }
  .router-transition {
    width: 100%;
    // height: 100%;
    min-height: 100vh;
    // min-height: -webkit-fill-available;
    // position: absolute;
    position: relative;
    transition: all 0.3s ease;
  }

  .slide-left-enter,
  .slide-right-leave-active {
    opacity: 0;
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0);
  }

  .slide-left-leave-active,
  .slide-right-enter {
    opacity: 0;
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100% 0);
  }
</style>
