import Moment from 'moment'

export const formatDate = (date, dateFormat) => {
  if (dateFormat === 0) {
    return Moment(date).format('YYYY/MM/DD')
  }
  if (dateFormat === 1) {
    return Moment(date).format('YYYY-MM-DD')
  }
  if (dateFormat === 2) {
    return Moment(date).format('YYYY年MM月DD日')
  }
  if (dateFormat === 4) {
    return Moment(date).format('YYYY年MM月')
  }
  if (dateFormat === 5) {
    return Moment(date).format('YYYY-MM')
  }
  if (dateFormat === 6) {
    return Moment(date).format('YYYY年')
  }
}
