<template>
  <div :class="$style.background"></div>
</template>
<script>
  export default {
    data() {
      return {}
    },
  }
</script>
<style lang="less" module>
  .background {
    width: 306px;
    height: 135px;
    background: url('../assets/imgs/backGround.png') no-repeat center;
    background-size: 306px 135px;
    position: absolute;
    top: 33px;
    left: 46px;
  }
</style>
