export default [
  {
    path: '/wxInner',
    name: 'wxInner',
    component: () => import('@/views/addressList/wxInner.vue'),
    meta: {
      whiteList: true,
    },
  },
  {
    path: '/notWxInner',
    name: 'notWxInner',
    component: () => import('@/views/addressList/notWxInner.vue'),
    meta: {
      title: '申请已发送',
      whiteList: true,
    },
  },
  {
    path: '/inviteExternalContact',
    name: 'inviteExternalContact',
    component: () => import('@/views/addressList/inviteExternalContact.vue'),
    meta: {
      title: '邀请外部联系人',
      whiteList: true,
    },
  },
  {
    path: '/inviteExternalContactHasLogin',
    name: 'inviteExternalContactHasLogin',
    component: () =>
      import('@/views/addressList/inviteExternalContactHasLogin.vue'),
    meta: {
      title: '邀请外部联系人',
    },
  },
  {
    path: '/inviteMember',
    name: 'inviteMember',
    component: () => import('@/views/addressList/inviteMember.vue'),
    meta: {
      title: '邀请企业成员',
      whiteList: true,
    },
  },
  {
    path: '/inviteMemberHasLogin',
    name: 'inviteMemberHasLogin',
    component: () => import('@/views/addressList/inviteMemberHasLogin.vue'),
    meta: {
      title: '邀请企业成员',
    },
  },
  {
    path: '/inviteFirend',
    name: 'inviteFirend',
    component: () => import('@/views/addressList/inviteFirend.vue'),
    meta: {
      title: '邀请好友',
      whiteList: true,
    },
  },
  {
    path: '/inviteFirendHasLogin',
    name: 'inviteFirendHasLogin',
    component: () => import('@/views/addressList/inviteFirendHasLogin.vue'),
    meta: {
      title: '邀请好友',
    },
  },
  {
    path: '/linkInvalid',
    name: 'linkInvalid',
    component: () => import('@/views/addressList/linkInvalid.vue'),
    meta: {
      title: '邀请链接失效',
      whiteList: true,
    },
  },
]
