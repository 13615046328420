import { get, post, postStatus } from './axios'

export default {
  // 填写文件应用变量
  addVariable(param) {
    return post('/core/contractSign/addVariable', param)
  },
  // 获取需要填写的文件应用变量
  getMyVariable(param) {
    return get('/core/contractSign/getMyVariable', param)
  },
  // 获取需要填写的签章和日期
  getSignAndDate(param) {
    return get('/core/contractSign/getSignAndDate', param)
  },
  // 填写文件签章
  addSignAndDate(param) {
    if (param.fileHtml) {
      return post('/core/contractSign/addSignAndDate', param)
    }
    return post('/core/newContractSign/addSignAndDate', param)
  },
  // 获取文件list
  getFileList(param) {
    return get('/core/contractSign/getFileList', param)
  },
  // 判断是否是最后一步（是否需要输入密码）
  getSignStatus(param) {
    return get('/core/contractSign/getSignStatus', param)
  },
  // 审批合同
  approve(param) {
    return post('/core/contractSign/approve', param)
  },
  // 查询批量业务列表
  batchList(params) {
    return post('/core/contractBusiness/batchList', params)
  },
  // 撤回合同
  revoke(params) {
    return post('/core/contractSign/revoke', params)
  },
  // 签署方式
  getMasterInfo(params) {
    return get('/core/contractSign/getMasterInfo', params)
  },
  getComOrUserStatus(params) {
    return get('/core/contractSign/getComOrUserStatus', params)
  },
  // 查看签署证据链
  evidenceList(params) {
    return get('/core/contractSign/getEvidence', params)
  },
  /**
   * 删除合同
   * @param {}} params
   */
  delete(params) {
    return get('/core/contractSign/delete', params)
  },

  // 校验加入状态
  checkJoinStatus(params) {
    return post('/core/contractSign/checkJoinStatus', params)
  },

  // 修改二维码状态
  updateScanCodeStatus(params) {
    return get('/core/contractSign/updateScanCodeStatus', params)
  },

  // 签署授权文件
  addEmpowerFile(params) {
    return post('/core/contractSign/addEmpowerFile', params)
  },

  // 获取 签署授权协议 html
  getEmpowerHtml(params) {
    return get('/core/contractSign/getEmpowerHtml', params)
  },
  // 签署成功
  getSignResult(params) {
    return get('/core/contractBusiness/getSignResult', params)
  },
  // 下载存证
  getDownloadEvidenceUrl(params) {
    return get('/core/contractSign/getDownloadEvidenceUrl', params)
  },
  // 下载合同
  getDownLoadFileUrl(params) {
    return get('/core/contractSign/getDownLoadFileUrl', params)
  },
  // 审批作废
  nullifyApprove(param) {
    return post('/core/newContractSign/approve', param)
  },
  // 作废签署方式
  getInvalidMasterInfo(params) {
    return get('/core/contractSign/getInvalidMasterInfo', params)
  },
  // 获取签署作废文件列表
  getInvalidFileList(params) {
    return get('/core/contractSign/getInvalidFileList', params)
  },
  // 作废签署成功
  getInvalidSignStatus(params) {
    return get('/core/contractSign/getInvalidSignStatus', params)
  },
  // 签署作废协议
  addCancelSignAndDate(params) {
    return post('/core/newContractSign/addCancelSignAndDate', params)
  },
  getInvalidComOrUserStatus(params) {
    return get('/core/contractSign/getInvalidComOrUserStatus', params)
  },
}
