export const tabbars = [
  {
    name: 'HomePage',
    title: '首页',
    normal: require('../../assets/imgs/homePagehead.svg'),
    active: require('../../assets/imgs/homePagehead1.svg'),
  },
  {
    name: 'List',
    title: '文件列表',
    // eslint-disable-next-line global-require
    normal: require('../../assets/imgs/homePagelist-icon.svg'),
    // eslint-disable-next-line global-require
    active: require('../../assets/imgs/homePagelist1-icon.svg'),
  },
  {
    name: 'My',
    title: '我的',
    // eslint-disable-next-line global-require
    normal: require('../../assets/imgs/homePagemy-icon.svg'),
    // eslint-disable-next-line global-require
    active: require('../../assets/imgs/homePage/homePagemy1-icon.svg'),
  },
]

export const zddTabbars = [
  {
    name: 'ZddHome',
    title: '首页',
    // eslint-disable-next-line global-require
    normal: require('../../assets/imgs/zdd_home_default.svg'),
    // eslint-disable-next-line global-require
    active: require('../../assets/imgs/zdd_home_active.svg'),
  },
  {
    name: 'List',
    title: '证书',
    //   // eslint-disable-next-line global-require
    normal: require('../../assets/imgs/zdd_certificate_default.svg'),

    //   // eslint-disable-next-line global-require
    active: require('../../assets/imgs/zdd_certificate_active.svg'),
  },
  // {
  //   name: 'ZddRecord',
  //   title: '证书',
  //   // eslint-disable-next-line global-require
  //   normal: require('../../assets/imgs/zdd_certificate_default.svg'),

  //   // eslint-disable-next-line global-require
  //   active: require('../../assets/imgs/zdd_certificate_active.svg'),
  // },
  {
    name: 'My',
    title: '我的',
    // eslint-disable-next-line global-require
    normal: require('../../assets/imgs/zdd_profile_default.svg'),
    // eslint-disable-next-line global-require
    active: require('../../assets/imgs/zdd_profile_active.svg'),
  },
]
