import router from '@/router'

function isCurrentRoute({ name, path }) {
  const { name: RouteName, path: RoutePath } = router.history.current
  if (name) {
    return name === RouteName
  }
  if (path) {
    return path === RoutePath
  }
  return false
}

export { isCurrentRoute }
