import loadingInstance from './loadingInstance'
import appendToBody from './appendToBody'
import activeElementScrollIntoView from './activeElementScrollIntoView'

export default {
  install(Vue) {
    Vue.mixin(appendToBody)
    Vue.mixin(loadingInstance)
    Vue.mixin(activeElementScrollIntoView)
  },
}
