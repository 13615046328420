export default [
  {
    path: '/signFilePage',
    name: 'signFilePage',
    component: () => import('@/views/signFileOut/signFilePage.vue'),
    meta: {
      title: '文件签署',
      whiteList: true,
    },
  },
  {
    path: '/addSignNamePage',
    name: 'addSignNamePage',
    component: () => import('@/views/signFileOut/addSignNamePage.vue'),
    meta: {
      title: '文件签署',
      whiteList: true,
    },
  },
  {
    path: '/signFileSuccess',
    name: 'signFileSuccess',
    component: () => import('@/views/signFileOut/signFileSuccess.vue'),
    meta: {
      title: '签署成功',
      whiteList: true,
    },
  },
  {
    path: '/signFilePreview',
    name: 'signFilePreview',
    component: () => import('@/views/signFileOut/signFilePreview.vue'),
    meta: {
      title: '文件预览',
      whiteList: true,
    },
  },
  {
    path: '/wxCallBack',
    name: 'wxCallBack',
    component: () => import('@/views/signFileOut/wxCallBack.vue'),
    meta: {
      title: '易云章',
      whiteList: true,
    },
  },
]
