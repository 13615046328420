const Launch = () => import('@/views/Launch/Launch.vue')
const SimpleLaunch = () => import('@/views/Launch/SimpleLaunch.vue')
const LaunchSuccess = () => import('@/views/Launch/LaunchSuccess.vue')
const InvitePartner = () => import('@/views/Launch/InvitePartner.vue')
const LaunchSignVar = () => import('@/views/Launch/LaunchSignVar.vue')
const LaunchSignSeal = () => import('@/views/Launch/LaunchSignSeal.vue')
const LaunchSelectUser = () => import('@/views/Launch/LaunchSelectUser.vue')
const FillFile = () => import('@/views/Launch/FillFile.vue')
const FillView = () => import('@/views/Launch/FillView.vue')
export default [
  {
    path: '/launch',
    name: 'Launch',
    component: Launch,
    meta: {
      title: '发起签署',
    },
  },
  {
    path: '/launchselectuser',
    name: 'LaunchSelectUser',
    component: LaunchSelectUser,
    meta: {
      title: '选择人员',
    },
  },
  {
    path: '/simplelaunch',
    name: 'SimpleLaunch',
    component: SimpleLaunch,
    meta: {
      title: '快速发起',
    },
  },
  {
    path: '/launchsignvar',
    name: 'LaunchSignVar',
    component: LaunchSignVar,
    meta: {
      title: '填写内容',
    },
  },
  {
    path: '/launchsignseal',
    name: 'LaunchSignSeal',
    component: LaunchSignSeal,
    meta: {
      title: '签署文件',
    },
  },
  {
    path: '/launchsuccess',
    name: 'LaunchSuccess',
    component: LaunchSuccess,
    meta: {
      title: '发起成功',
    },
  },
  {
    path: '/invitepartner',
    name: 'InvitePartner',
    component: InvitePartner,
    meta: {
      title: '邀请参与方',
    },
  },
  {
    path: '/fillfile',
    name: 'FillFile',
    component: FillFile,
    meta: {
      title: '变量填写',
    },
  },
  {
    path: '/fillView',
    name: 'FillView',
    component: FillView,
    meta: {
      title: '文件列表',
    },
  },
]
