import { Toast } from 'vant'

/**
 * 页面级loading mixin
 */
export default {
  data: () => ({
    loadingInstance: null,
  }),
  methods: {
    /**
     * 创建loading实例，展示loading
     */
    createLoadingInstance() {
      this.loadingInstance = Toast.loading({
        message: '加载中...',
        forbidClick: false,
        loadingType: 'spinner',
      })
    },

    /**
     * 清除loading
     */
    clearLoadingInstance() {
      this.loadingInstance.clear()
    },
  },
}
