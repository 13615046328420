export default {
  install: () => {
    // https://cn.vuejs.org/v2/guide/components-registration.html#基础组件的自动化全局注册
    const requireComponent = require.context(
      // 其组件目录的相对路径
      '@/assets/icons/svg/',
      // 是否查询其子目录
      false,
      // 匹配基础组件文件名的正则表达式
      /\.svg$/
    )

    const requireAll = requireContext => {
      requireContext.keys().map(requireContext)
    }
    requireAll(requireComponent)
  },
}
