import { Toast } from 'vant'
/**
 * 复制方法
 *
 * @param {string} value 要复制的值
 * @param {string} message 复制成功后的提示文案
 */
export const copy = (value, message = '复制成功') => {
  const oInput = document.createElement('input')
  // 避免出现input聚焦而弹出键盘
  oInput.type = 'hidden'
  oInput.value = value
  document.body.appendChild(oInput)
  oInput.select() // 选择对象
  document.execCommand('Copy') // 执行浏览器复制命令
  oInput.className = 'oInput'
  oInput.style.display = 'none'
  Toast(message)
}

export const copyArticle = value => {
  const div = document.createElement('div')
  div.innerText = value
  const range = document.createRange()
  range.selectNode(div)

  const selection = window.getSelection()
  if (selection.rangeCount > 0) selection.removeAllRanges()
  selection.addRange(range)
  document.execCommand('Copy')
  Toast('复制成功！')
}

export const copyText = text => {
  // 数字没有 .length 不能执行selectText 需要转化成字符串
  const textString = text.toString()
  let input = document.querySelector('#copy-input')
  if (!input) {
    input = document.createElement('input')
    input.id = 'copy-input'
    input.readOnly = 'readOnly' // 防止ios聚焦触发键盘事件
    input.style.position = 'absolute'
    input.style.left = '-1000px'
    input.style.zIndex = '-1000'
    document.body.appendChild(input)
  }

  // input自带的select()方法在苹果端无法进行选择，所以需要自己去写一个类似的方法
  // 选择文本。createTextRange(setSelectionRange)是input方法
  function selectText(textbox, startIndex, stopIndex) {
    if (textbox.createTextRange) {
      // ie
      const range = textbox.createTextRange()
      range.collapse(true)
      range.moveStart('character', startIndex) // 起始光标
      range.moveEnd('character', stopIndex - startIndex) // 结束光标
      range.select() // 不兼容苹果
    } else {
      // firefox/chrome
      textbox.setSelectionRange(startIndex, stopIndex)
      textbox.focus()
    }
  }

  input.value = textString
  // ios必须先选中文字且不支持 input.select();
  selectText(input, 0, textString.length)
  document.execCommand('copy')
  if (document.execCommand('copy')) {
    document.execCommand('copy')
    alert('已复制到粘贴板')
  } else {
    console.log('不兼容')
  }
  input.blur()
}

export const copyValue = value => {
  const input = document.createElement('input')
  input.id = 'copy-input'
  input.readOnly = 'readOnly' // 防止ios聚焦触发键盘事件
  input.style.position = 'absolute'
  input.style.left = '-1000px'
  input.style.zIndex = '-1000'
  document.body.appendChild(input)
  input.value = value
  if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    const el = input.get(0)
    const editable = el.contentEditable
    const { readOnly } = el
    el.contentEditable = 'true'
    el.readOnly = 'false'
    const range = document.createRange()
    range.selectNodeContents(el)
    const sel = window.getSelection()
    sel.removeAllRanges()
    sel.addRange(range)
    el.setSelectionRange(0, 999999)
    el.contentEditable = editable
    el.readOnly = readOnly
  } else {
    input.select()
  }
  document.execCommand('copy')
  input.blur()
}

/**
 * Copy a string to clipboard
 * @param  {String} string         The string to be copied to clipboard
 * @return {Boolean}               returns a boolean correspondent to the success of the copy operation.
 */
export function copyToClipboard(string) {
  let textarea
  let result

  try {
    textarea = document.createElement('textarea')
    textarea.setAttribute('readonly', true)
    textarea.setAttribute('contenteditable', true)
    textarea.style.position = 'fixed' // prevent scroll from jumping to the bottom when focus is set.
    textarea.value = string

    document.body.appendChild(textarea)

    textarea.focus()
    textarea.select()

    const range = document.createRange()
    range.selectNodeContents(textarea)

    const sel = window.getSelection()
    sel.removeAllRanges()
    sel.addRange(range)

    textarea.setSelectionRange(0, textarea.value.length)
    result = document.execCommand('copy')
  } catch (err) {
    console.error(err)
    result = null
  } finally {
    document.body.removeChild(textarea)
  }

  // manual copy fallback using prompt
  if (!result) {
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0
    const copyHotkey = isMac ? '⌘C' : 'CTRL+C'
    result = prompt(`Press ${copyHotkey}`, string) // eslint-disable-line no-alert
    if (!result) {
      return false
    }
  }
  return true
}
