import store from '@/store'

/**
 * 权限验证方法
 *
 * @param {string[]} permissions
 * @return {boolean}
 */
export function hasAuth(permissions) {
  const { authList, isComUser } = store.getters && store.getters
  if (
    authList &&
    permissions &&
    Array.isArray(permissions) &&
    permissions.length > 0
  ) {
    const res = permissions.some(permission => authList.includes(permission))
    return !(isComUser && !res)
  }
}
