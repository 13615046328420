import store from '@/store'
import { get, post } from '../axios'

const systemInfo = store.state.system

export default {
  // 获取微信 config
  signURL(param) {
    param = {
      ...param,
      systemId: systemInfo.systemId,
    }
    return get('/user/wechat/signURL', param)
  },
}
