<template>
  <div :class="$style.wrap">
    <div>
      <img
        :class="$style.icon"
        src="@/assets/imgs/路径@2x.png"
        alt
        v-if="showBack"
        @click="goBack"
      />
      {{ title }}
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      title: {
        type: String,
        default: '个人身份认证服务',
      },
    },
    data() {
      return {}
    },
    computed: {
      // eslint-disable-next-line vue/return-in-computed-property
      showBack() {
        if (this.$route.name === 'List' || this.$route.name === 'My') {
          return false
        }
        return true
      },
    },
    methods: {
      goBack() {
        this.$router.goBack()
      },
    },
  }
</script>
<style lang="less" module>
  .wrap {
    // height: 44px;
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(67, 71, 81, 1);
    line-height: 44px;
    text-align: center;
    position: relative;
    // margin-bottom: 16px;
    background-color: #fff;
    .icon {
      position: absolute;
      width: 10px;
      height: 18px;
      left: 20px;
      top: 14px;
      transform: rotate(180deg);
    }
  }
</style>
