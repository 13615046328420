import store from '@/store'
import router from '@/router'
import coreApi from '@/api/core'
import sealApi from '@/api/seal'
import { generateRandomKey, isFileType } from '@/utils/helper'
import { Toast } from 'vant'
import cloneDeep from 'lodash.clonedeep'
import moment from 'moment'

import {
  PARTNER_ROLE,
  TEMPLATE_SOURCE,
  SAVE_DRAFT_STEP,
  ASSIGN_TYPE,
} from '@/common/enum'
import user from '@/store/module/user'

const isCom = () => !!store.getters.getUserInfo.comId
const isLegalPerson = () => store.getters.getUserInfo.comSystemAdminType === 1
const userInfo = () => !!store.getters.getUserInfo
const getCurUserCanUseSealList = () => store.getters.curUserCanUseSealList

// 创建随机数
const getRandom = length => {
  let Num = ''
  let i
  for (i = 0; i < length; i++) {
    Num += Math.floor(Math.random() * 10)
  }
  return Num
}

/**
 * 当前参与方是否为发起方
 *
 * 1、发起方本人 assignType === 5
 *
 * 2、法人 assignType === 4
 *
 * 3、指定人 assignType === 0
 *
 * 4、指定章 assignType === 1
 */
function curPartnerIsLauncher(partner) {
  const { assignType, assignId, assignIdList } = partner

  // 发起方
  if (assignType === ASSIGN_TYPE.LAUNCHER) {
    return true
  }

  // 法人
  if (assignType === ASSIGN_TYPE.LEGAL_PERSON) {
    // 判断当前用户是否为法人
    return isLegalPerson()
  }

  // 指定人 判断当前参与方指定的 id 和 userId 是否是一个
  if (assignType === ASSIGN_TYPE.APPOINT_PERSON) {
    return assignId === userInfo().userId
  }
  // 指定章
  if (assignType === ASSIGN_TYPE.APPOINT_SEAL) {
    // 指定章的时候能指定人，如果指定人了，要判断指定的人里边有没有当前发起方
    if (assignIdList && assignIdList.length) {
      // 说明此时指定人了
      return assignIdList.includes(userInfo().userId)
    }
    // 这里是没有指定签章人，只判断当前人是否有签章权限
    const sealList = getCurUserCanUseSealList()
    const sealIdList = sealList.map(seal => seal.sealId)
    return sealIdList.includes(assignId)
  }

  return false
}

/**
 * 从 partnerList 中找到当前的发起方
 * 两种情况
 * 1、顺序签 第一个参与方是不是发起方
 * 2、无序签 找发起方判断
 *
 * @param {Array} partnerList - 参与方 list
 * @param {number} signOrderFlag - 1-顺序签 2-无序签
 */
function getCurLauncher(partnerList, signOrderFlag) {
  // 顺序签
  if (signOrderFlag === 1) {
    // 先判断当前参与方列表的 第一项 是否为内部
    if (partnerList && partnerList.length) {
      const curPartner = partnerList[0]
      if (curPartner.partnerRole === 0) {
        if (curPartnerIsLauncher(curPartner)) {
          curPartner.isLauncher = 1
          return curPartner
        }
        return undefined
      }
      return undefined
    }
    return undefined
  }
}

/**
 * 是否当前的文件列表里有需要去填写的变量
 * - 只要文件里含有没填写的变量就返回 `true`
 *
 * @param fileList
 */
const hasVarParamNeedToSign = fileList => {
  return fileList.find(file => {
    return file.comVarParamList.some(comVarParam => {
      return !comVarParam.varValue
    })
  })
}

// 格式化 从创建应用里带过来的 title
const formatTitleFromBusinessTemplate = businessInfo => {
  try {
    const {
      launchTitlePrefix: prefix,
      launchTitleContent: titleContent,
      classifyName,
      partnerList,
    } = businessInfo
    if (prefix && titleContent) {
      let str = ''
      const titleRuleTemplate = JSON.parse(titleContent)
      titleRuleTemplate &&
        titleRuleTemplate.forEach(item => {
          // 模板分类
          // 本企业名称
          // 发起签署日期
          switch (item.title) {
            case '本企业名称':
              str += `-${userInfo().comName}`
              break
            case '模板分类':
              str += `-${classifyName}`
              break
            case '发起签署日期':
              str += `-${moment().format('YYYY年MM月DD日')}`
              break
            default:
              if (item.title.startsWith('参与方')) {
                partnerList.forEach(partner => {
                  // 只能这样来匹配 因为 title 可能是 参与方XXX 导致 不能直接用名字全等于
                  if (item.title.startsWith(partner.defaultName)) {
                    // 如果是参与方的名字要替换成 名字/手机号
                    // 如果没有名字 要展示 未知个人/未知企业
                    const { signerList, partnerRole, comName } = partner
                    if (businessInfo.batchSign) {
                      // 批量的发起让服务端去拼，前端不处理
                    } else {
                      if (partnerRole === 0) {
                        // 发起方，如果是企业展示企业名字
                        if (isCom()) {
                          str += `-${userInfo().comName}`
                        } else {
                          // 如果是个人 展示当前人的名字
                          str += `-${userInfo().name}`
                        }
                      } else if (partnerRole === 1) {
                        // 外部个人要取个人的名字
                        const signer = signerList && signerList[0]
                        if (
                          signer &&
                          (signer.handlerName || signer.handlerTel)
                        ) {
                          const { handlerName, handlerTel } = signer
                          str += `-${handlerName || handlerTel}`
                        } else {
                          // 如果没有姓名或者手机号
                          str += '-未知个人'
                        }
                      } else if (partnerRole === 2) {
                        const signer = signerList && signerList[0]
                        if (signer && signer.comName) {
                          // 外部企业要取企业的名字
                          str += `-${signer.comName}`
                        } else {
                          str += '-未知企业'
                        }
                      }
                    }
                  }
                })
              } else {
                str += item.title
              }
          }
        })
      str = `${prefix}${str}`
      return str
    }
  } catch (error) {
    console.error(error)
  }
}

/**
 * 创建一个 `input` 并触发点击事件可以上传文件，返回读取到的file
 * @return {Promise<Blob>}
 */
const handleInputFile = () => {
  return new Promise((resolve, reject) => {
    // const input = document.createElement('input')
    // input.type = 'file'
    const input = document.getElementsByClassName('inputFile')[0]
    input.addEventListener('change', e => {
      const file = e.target.files[0]
      resolve(file)
      setTimeout(() => {
        input.value = null
      }, 0)
    })
    input.click()
  })
}
/**
 * 如果是 jpeg 类型的图片将其转成 png
 * @param {Base64} fileBase64
 */
const transferJPEGtoPNG = fileBase64 => {
  return new Promise(resolve => {
    const image = new Image()
    image.src = fileBase64
    image.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(image, 0, 0, image.width, image.height)
      const dataURL = canvas.toDataURL('image/png')
      resolve(dataURL)
    }
  })
}

/**
 * 将文件转成base64，返回文件的base64编码
 * @param {File} file - 要进行编码的 `file`
 * @return {Promise<string>}
 */
const translateFileToBase64 = file => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = e => {
      const res = e.target.result
      resolve(res)
    }
    fileReader.readAsDataURL(file)
  })
}

/**
 * 获取文件信息
 * @param {File} file
 * @return {Promise<{objectFormat: string, objectBase64: string}>}
 */
const getFileData = async file => {
  try {
    const { name: fileName, type, size } = file
    let objectFormat = `.${fileName.substr(fileName.lastIndexOf('.') + 1)}`
    if (isFileType(objectFormat)) {
      return {
        objectFormat: '',
        objectBase64: '',
        size: 0,
      }
    }
    let objectBase64 = await translateFileToBase64(file)
    if (type === 'image/jpeg') {
      objectBase64 = await transferJPEGtoPNG(objectBase64)
      objectFormat = '.png'
    }
    return {
      objectFormat,
      objectBase64,
      size,
    }
  } catch (error) {
    console.error(error)
  }
}

/**
 * 上传文件
 * @param {string} objectBase64 - 文件base64编码
 * @param {string} objectFormat - 文件类型后缀
 * @return {{fileKey:string, fileUrl:string, imgList:string[]}}
 */
const uploadFile = async ({ objectBase64, objectFormat }, file, randomKey) => {
  try {
    // const fileModel = {
    //   objectBase64,
    //   objectFormat,
    // }
    // return await coreApi.templateFile.uploadFile(fileModel, c => {
    //   store.commit('launch/setProgressFile', {
    //     fileName: file.name,
    //     fileSize: file.size,
    //     objectFormat,
    //     cancel: c,
    //     randomKey,
    //   })
    // })

    const fileModel = {
      fileName: file.name,
      objectBase64,
      objectFormat,
      type: 1,
      radomId: generateRandomKey(),
    }
    store.commit('launch/setProgressFile', {
      fileName: file.name,
      fileSize: file.size,
      objectFormat,
      randomKey,
      filePercentage: 95,
    })
    const res = await coreApi.templateFile.uploadFileToSaaS(fileModel, c => {
      store.commit('launch/delProgressFile')
      store.commit('launch/setProgressFile', {
        fileName: file.name,
        fileSize: file.size,
        objectFormat,
        cancel: c,
        randomKey,
        filePercentage: 100,
      })
    })
    if (!res.taskId) {
      const fileModel2 = {
        fileKey: res.fileKey,
      }
      const data = await coreApi.templateFile.getImageList(fileModel2)
      return {
        ...res,
        fileUrl: data && data[0],
        imgList: data,
      }
    }
    return {
      ...res,
    }

    // return await coreApi.templateFile.uploadFileToSaaS(fileModel, c => {
    //   store.commit('launch/setProgressFile', {
    //     fileName: file.name,
    //     fileSize: file.size,
    //     objectFormat,
    //     cancel: c,
    //     randomKey,
    //   })
    // })
  } catch (error) {
    store.commit('launch/delProgressFile')
    console.error(error)
  } finally {
    // Toast.clear()
  }
}

const createLauncherModel = () => {
  const partnerIndex = generateRandomKey(8, false)
  let launcherModel
  const defaultPartnerLauncherModel = {
    partnerIndex,
    defaultName: '参与方1',
    // 是否指定盖章人，如果不指定则为 undefined 如果指定了 则要存储uid
    uid: undefined,
    // 指定章的id
    assignId: undefined,
    // 指定章
    assignType: 1,
    partnerRole: 0,

    // 个人身份
    handlerTel: undefined,
    handlerName: undefined,
  }
  if (isCom()) {
    // 如果是企业身份
    launcherModel = defaultPartnerLauncherModel
  } else {
    launcherModel = {
      ...defaultPartnerLauncherModel,
      partnerIndex,
      uid: undefined,
      assignId: undefined,
      // 指定章
      assignType: undefined,
      partnerRole: 0,
      handlerTel: store.getters.getUserInfo.telephone,
      handlerName: store.getters.getUserInfo.name,
    }
  }
  return launcherModel
}

/**
 * Zdd 颁发证书发起流程
 *
 * 1. 根据 `businessId` 获取草稿信息
 * 2. 显示通讯录选择内部成员，添加到第二个参与方中，要根据参与方的数量判断是否为批量
 * 3. 默认发起方设置为默认章
 * 4. 直接保存获取到 `masterId` ，根据变量的情况判断跳转的页面
 *    - 如果没有变量，跳转到签署页面
 *    - 如果有变量，但是不需要填写(所有变量填写完毕)，跳转到签署页面
 *    - 如果有变量未填，则跳转到填写变量页面
 *
 *  @param {string} businessId - businessId
 *  @param {Array<{position:string,handlerName:string,handlerTel:number,comName:string,comId:string,uid:string}>} userList - 选中的用户列表
 *  @param {object} vm
 *
 * @return {Promise<void>}
 */
const createZddLaunch = async ({ businessId, userList, vm }) => {
  try {
    store.commit('sign/resetSignState')

    const templateBusinessInfo = await coreApi.templateBusiness.getTemplateBusiness(
      { businessId }
    )
    const model = cloneDeep(templateBusinessInfo)

    if (model.fileList && model.fileList.length) {
      model.fileList.forEach(file => {
        file.templateSource = TEMPLATE_SOURCE.TEMPLATE_APP
      })
    }
    model.descName = model.launchDesc

    model.partnerList.forEach(partner => {
      partner.fileMap = {}
      partner.guid = getRandom(10)
      if (
        partner.partnerRole === PARTNER_ROLE.PARTNER_COM ||
        partner.partnerRole === PARTNER_ROLE.PARTNER_PERSONAL
      ) {
        if (!partner.signerList) {
          partner.signerList = []
        }
        partner.assignName = partner.handlerName
      }
      if (partner.batchSign == null) {
        partner.batchSign = 0
      }
    })
    model.partnerList[1].signerList = cloneDeep(userList)

    // 是否有需要填写的变量
    const hasNeedSignVarParamFile = hasVarParamNeedToSign(model.fileList)

    // 批量发起
    if (userList.length > 1) {
      model.batchSign = 1
      model.partnerList[1].batchSign = 1
    } else {
      const curUser = userList[0]
      // 非批量发起
      model.batchSign = 0
      model.assignId = curUser.uid

      // 这里要对变量信息做处理，对变量 varTitle 为 「姓名」「岗位」的时候要直接替换
      model.fileList.forEach(file => {
        file.comVarParamList.forEach(comVarParam => {
          if (comVarParam.varTitle === '姓名') {
            comVarParam.varValue = curUser.handlerName
          } else if (comVarParam.varTitle === '岗位') {
            comVarParam.varValue = curUser.position
          }
        })
      })
    }
    model.launchSign = 0

    model.numStep = hasNeedSignVarParamFile
      ? SAVE_DRAFT_STEP.SIGN_VAR_PAGE
      : SAVE_DRAFT_STEP.SIGN_SELF

    model.step = SAVE_DRAFT_STEP.LAUNCH_FORM_PAGE
    model.launchFromBusinessId = businessId

    // 处理标题
    model.titleName = formatTitleFromBusinessTemplate(model)

    const sealList = await sealApi.getSealListByStatus()
    let defaultSeal = sealList.find(seal => seal.defaultSeal === 1)
    // 如果没有有默认章，取第一个
    if (!defaultSeal) {
      ;[defaultSeal] = sealList
    }
    const { sealId } = defaultSeal
    // 找到默认章的 id
    model.partnerList[0].assignId = sealId
    const res = await store.dispatch('sign/saveWithStatus', {
      launchInfo: model,
      vm,
    })

    if (res) {
      if (hasNeedSignVarParamFile) {
        router.push({
          name: 'LaunchSignVar',
          query: {
            masterId: res.masterId,
          },
        })
      }
    } else {
      router.push({
        name: 'LaunchSignSeal',
        query: {
          master: res.masterId,
        },
      })
    }
  } catch (error) {
    console.error(error)
  }
}

/**
 * 上传一个文件，并添加到 launchModel 中，跳转到极简发起页面
 * @return {Promise<void>}
 */
const createSimpleLaunch = async fileModel => {
  try {
    // 因为上传文件列表没有用 namespace 的方式来处理，所以每次发起之前先清空之前的上传文件列表
    store.commit('launch/clearProgressFileList')
    let fileInfo = {}
    if (fileModel) {
      const randomKey = generateRandomKey(8, false)
      const {
        imgList,
        fileUrl,
        ecloudId,
        fileKey,
        fileName,
        fileSize,
      } = fileModel
      fileInfo = {
        fileIndex: 0,
        fileName,
        fileSize,
        fileKey,
        fileUrl,
        imgList,
        pageNum: imgList.length,
        randomKey,
      }
    } else {
      const file = await handleInputFile()
      if (file) {
        if (file.size > 50 * 1024 * 1024) {
          Toast('文件大小不能超过50M')
          return
        }
        const { objectFormat, objectBase64 } = await getFileData(file)
        // console.log(objectFormat, objectBase64)
        if (!objectFormat) {
          Toast('只支持 .png .jpg .jpeg .doc .docx .xls .xlsx格式的文件')
          return
        }
        // debugger
        router.push({
          name: 'SimpleLaunch',
          query: {
            key: '',
          },
        })
        const { name: fileName, size: fileSize } = file
        const randomKey = generateRandomKey(8, false)
        const data = await uploadFile(
          {
            objectBase64,
            objectFormat,
          },
          file,
          randomKey
        )
        if (!data) {
          return
        }
        const { fileKey, fileUrl, imgList } = data
        fileInfo = {
          fileIndex: 0,
          fileName,
          fileSize,
          fileKey,
          fileUrl,
          imgList,
          pageNum: imgList.length,
          randomKey,
        }
      }
    }

    const simpleLaunchModel = cloneDeep(
      store.getters['launch/getSimpleDefaultModel']
    )
    const launcherModel = createLauncherModel()
    launcherModel.partnerSubList = [
      {
        // 文件索引
        fileIndex: '-1',
        // 文件key
        fileKey: null,
        // 指定操作类型：0-个人签署 1-个人审批 2-经办人签署 3-法人签名 4-企业签章
        operateList: isCom() ? [4] : [1],
        // 指定操作类型：0-个人签署 1-个人审批 2-经办人签署 3-法人签名 4-企业签章
        operateType: isCom() ? 4 : 1,
      },
    ]
    simpleLaunchModel.fileList.push(fileInfo)
    simpleLaunchModel.partnerList.push(launcherModel)
    // eslint-disable-next-line prefer-destructuring
    const arr = fileInfo.fileName.split('.')
    const typeLen = arr[arr.length - 1].length
    const title = fileInfo.fileName.slice(0, (typeLen + 1) * -1)
    simpleLaunchModel.titleName = title.substring(0, 100)
    store.commit('launch/setSimpleLaunchModel', {
      key: [fileInfo.randomKey],
      obj: simpleLaunchModel,
    })
    const fileList = store.getters['launch/getProgressFile']
    fileList.forEach((item, index) => {
      // eslint-disable-next-line eqeqeq
      if (item.randomKey == fileInfo.randomKey) {
        fileList.splice(index, 1)
      }
    })
    // console.log('fileInfo',fileInfo);
    await router.replace({
      name: 'SimpleLaunch',
      query: {
        key: fileInfo.randomKey,
      },
    })
  } catch (error) {
    console.error(error)
  }
}

export {
  createSimpleLaunch,
  uploadFile,
  translateFileToBase64,
  handleInputFile,
  getFileData,
  createZddLaunch,
  getCurLauncher,
}
