import store from '@/store'
import { get, post } from './axios'

const systemId = () => store.state.system.systemId

const passportApis = {
  /**
   * 登录
   * @param {*} param
   */
  login(param) {
    return post('/user/passport/login', param)
  },
  /**
   * 登录
   * @param {*} param
   */
  init(param) {
    return get('/user/passport/init', param)
  },

  /**
   * 退出登录
   * @param {*} param
   */
  loginOut(param) {
    return get('/user/passport/loginOut', param)
  },

  /**
   * 获取图片验证码
   * @param {*} param
   */
  getPictureCode(param) {
    return get('/user/passport/getPictureCode', param)
    // return get('/passport/getPictureCode', param)
  },

  /**
   * 验证手机号是否注册和图片验证码
   * @param {*} param
   */
  checkAccountAndPictureCode(param) {
    return post('/user/passport/checkAccountAndPictureCode', param)
    // return post('/passport/checkAccountAndPictureCode', param)
  },

  /**
   * 获取邮箱验证码
   */
  getEmailCode(param) {
    return get('/user/passport/getEmailCode', param)
  },

  /**
   * 获取手机验证码
   */
  getTelCode(param) {
    param = {
      ...param,
      systemId: systemId(),
    }
    return get('/user/passport/getTelCode', param)
  },

  /**
   * 通过手机号注册
   */
  registerByTel(param) {
    return post('/user/passport/registerByTel', param)
  },

  /**
   * 重置登录密码
   */
  resetLoginPwd(param) {
    return post('/user/passport/resetLoginPwd', param)
  },
  /**
   * 跳过设置密码
   */
  skipSetPassword(param) {
    return get('/user/passport/skipSetPassword', param)
  },
  /**
   * 埋点
   */
  uploadURL(param) {
    return get('/user/passport/uploadURL', param)
  },
  addUserBasicInfo(param) {
    return post('/user/passport/addUserBasicInfo', param)
  },
}

export default passportApis
