import Vue from 'vue'

// filters
import filters from '@/filters'

import unload from '@/plugins/unload'
import Header from '@/components/Header.vue'
import svgIcons from './svgIcons'
import registerGlobalComponents from './registerGlobalComponents'
import vant from './vant'
import clipboard from './clipboard'
import activeElementScrollIntoView from './activeElementScrollIntoView'
import showChargeDialog from './showChargeDialog'

export default {
  install: () => {
    Vue.use(svgIcons)
    Vue.use(registerGlobalComponents)
    Vue.component('Header', Header)
    Vue.use(unload)
    // Vue.use(activeElementScrollIntoView);
    Vue.use(vant)
    Vue.use(clipboard)
    Vue.use(showChargeDialog)
    Vue.use(filters)
  },
}
