/**
 * 修复 iOS 系统 微信环境下 input 键盘弹起后页面收不回来的问题
 */
window.addEventListener('resize', () => {
  if (
    document.activeElement.tagName === 'INPUT' ||
    document.activeElement.tagName === 'TEXTAREA' ||
    document.activeElement.getAttribute('contenteditable') === 'true'
  ) {
    window.setTimeout(() => {
      if ('scrollIntoView' in document.activeElement) {
        document.activeElement.scrollIntoView()
      } else {
        document.activeElement.scrollIntoViewIfNeeded()
      }
    }, 0)
  }
})
