import {
  baseHeight,
  baseWidth,
  paginationHeight,
} from '@/common/filePreviewConfig'

const bodyClientRect = {
  clientWidth: undefined,
  clientHeight: undefined,
}

/**
 * 获取 body 的 `clientWidth` `clientHeight`
 *
 * @return {{clientWidth: number, clientHeight: number}}
 */
export function getBodyClientRect() {
  window.addEventListener('resize', getBodyClientRect)
  // 缓存中取值
  if (
    bodyClientRect.clientWidth !== undefined &&
    bodyClientRect.clientHeight !== undefined
  ) {
    return bodyClientRect
  }

  // 缓存中没有值
  const body = document.documentElement || document.body
  const { clientWidth, clientHeight } = body
  bodyClientRect.clientHeight = clientHeight
  bodyClientRect.clientWidth = clientWidth
  return bodyClientRect
}

// 默认先执行一次
getBodyClientRect()

const fileRatio = 595 / 842

/**
 * 获取要签署的时候的图片的高度
 *
 * @return {number}
 */
export function getFileImgHeight() {
  const { clientWidth } = getBodyClientRect()
  return clientWidth / fileRatio
}

/**
 * 计算图片容器的高度，所有的页面的高度加上分割区的高度
 *
 * @param {number} wrapWidth - 图片容器的宽度
 * @param {number} pageNum - 页码数
 * @return {number}
 */
export function getImgWrapHeight(wrapWidth, pageNum) {
  const ratio = wrapWidth / baseWidth
  return ratio * baseHeight * pageNum + paginationHeight * (pageNum - 1)
}
