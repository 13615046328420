export default [
  {
    path: '/publish/template',
    name: 'publishTemplate',
    component: () => import('@/views/publish/template'),
    meta: {
      title: '模版发起',
    },
  },
  {
    path: '/publish/packing',
    name: 'publishPacking',
    component: () => import('@/views/publish/packing'),
    meta: {
      title: '应用发起',
    },
  },
]
