import { get, post, postStatus } from './axios'

export default {
  // 获取合同业务详情
  detail(params) {
    return get('/core/contractBusiness/detail', params)
  },
  list(params) {
    return post('/core/contractBusiness/list', params)
  },
  /**
   * 补全参与方信息
   */
  modifyPartnerInfo(params) {
    return post('/core/contractBusiness/modifyPartnerInfo', params)
  },
  /**
   * 催签
   */
  updateUrgingSignature(params) {
    return post('/core/contractBusiness/updateUrgingSignature', params)
  },
  /**
   * 极简发起
   * @param params
   * @return {Promise | Promise<unknown>}
   */
  simpleLaunch(params) {
    return post('/core/contractBusiness/simpleLaunch', params)
  },
  /**
   * 删除
   */
  deleteContract(params) {
    return post('/core/contractBusiness/deleteContract', params)
  },

  /**
   * 发起签署 返回值不处理异常状态 返回 data code msg
   *
   * @param {*} param
   */
  saveWithStatus(param) {
    if (param.fileList[0] && param.fileList[0].htmlContent) {
      return postStatus('/core/contractBusiness/save', param)
    }
    return postStatus('/core/newContractBusiness/save', param)
  },

  /**
   * 草稿
   *
   * @param param
   * @return {Promise | Promise<unknown>}
   */
  draft(param) {
    return get('/core/contractBusiness/draft', param)
  },
  // 获取合同业务详情
  queryInvalidBusinessDetail(params) {
    return get('/core/contractBusiness/queryInvalidBusinessDetail', params)
  },
}
