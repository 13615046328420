/* eslint-disable */
import axios from 'axios'
import Qs from 'qs'
import Vue from 'vue'
import Router from '@/router'
import { Toast } from 'vant'
import store from '@/store'
import { isWxMiniProgram } from '@/utils/ua'
import { navigateToMiniLogin } from '@/utils/mini'
import { isCurrentRoute } from '@/utils/isCurrentRoute'

Vue.use(Toast)
axios.defaults.baseURL = '/api'

// 设置请求超时时间
axios.defaults.timeout = 90000

// axios.defaults.withCredentials = true;
axios.interceptors.request.use(
  config => {
    const token = window.sessionStorage.getItem('token') || ''
    config.headers['token'] = token
    // 判断数据类型切换头部默认是json
    if (config.commitType) {
      if (config.commitType === 'form') {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        config.transformRequest = [
          function(data) {
            return Qs.stringify(data) // 利用对应方法转换格式
          },
        ]
      }
    }
    return config
  },
  error => {
    // 对请求错误做些什么
    Toast('请求超时')
    return Promise.error(error)
  }
)

// 响应拦截
axios.interceptors.response.use(
  response => {
    // 对响应数据做点什么
    return response
  },
  error => {
    const { status, data } = error.response
    if (status === 401) {
      console.log('401')

      // 加个延迟，保证页面跳转完毕
      setTimeout(() => {
        if (
          isCurrentRoute({ name: 'My' }) ||
          isCurrentRoute({ name: 'List' })
        ) {
          return
        }
        console.log('延迟');
        if (isWxMiniProgram()) {
          let redirectUrl = Router.currentRoute.fullPath
          console.log('401')
          navigateToMiniLogin(true, encodeURIComponent(redirectUrl))
        } else {
          const isZdd = store.getters['system/isZdd']
          let redirectUrl = Router.currentRoute.fullPath
          if (isZdd && Router.currentRoute.name === 'List') {
            redirectUrl = '/zdd/home'
          }
          console.log('replace')
          Router.replace({
            path: '/login',
            query: {
              redirect: redirectUrl,
            },
          })
        }
      }, 100)
      return
    }
    return Promise.reject(error)
  }
  // 对响应错误做点什么
)

// 封装get方法和post方法

// 处理返回数据
const successFn = (fn, res, reject) => {
  if (res.data.status === 201) {
    if (res.data.msg) {
      Toast(res.data.msg)
    }
    reject(res.data)
    return
  }
  if (res.data.status === 200) {
    fn(res.data.data)
    return
  }
  if (res.data.status === 9001) {
    // if (isWxMiniProgram()) {
    //   console.log('9001')
    //   // navigateToMiniLogin()
    // } else {
    //   Router.push('/login')
    // }
    if (isWxMiniProgram()) {
      let redirectUrl = Router.currentRoute.fullPath
      console.log('9001')
      navigateToMiniLogin(true, encodeURIComponent(redirectUrl))
    } else {
      const isZdd = store.getters['system/isZdd']
      let redirectUrl = Router.currentRoute.fullPath
      if (isZdd && Router.currentRoute.name === 'List') {
        redirectUrl = '/zdd/home'
      }
      console.log('replace')
      Router.replace({
        path: '/login',
        query: {
          redirect: redirectUrl,
        },
      })
    }
    return
  }
  reject(res.data)
}

const errFn = (fn, res) => {
  // if (res && res.data && res.data.msg) {
  //   Toast(res.data.msg);
  // }
  fn(res && res.data)
}

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params,
      })
      .then(res => {
        successFn(resolve, res, reject)
      })
      .catch(err => {
        errFn(reject, err)
      })
  })
}

export function getStatus(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params,
      })
      .then(res => {
        if (res.data.status === 9001) {
          // if (isWxMiniProgram()) {
          //   navigateToMiniLogin()
          // } else {
          //   Router.push('/login')
          // }
          if (isWxMiniProgram()) {
            let redirectUrl = Router.currentRoute.fullPath
            console.log('9001')
            navigateToMiniLogin(true, encodeURIComponent(redirectUrl))
          } else {
            const isZdd = store.getters['system/isZdd']
            let redirectUrl = Router.currentRoute.fullPath
            if (isZdd && Router.currentRoute.name === 'List') {
              redirectUrl = '/zdd/home'
            }
            console.log('replace')
            Router.replace({
              path: '/login',
              query: {
                redirect: redirectUrl,
              },
            })
          }
          return
        }
        resolve(res.data)
      })
      .catch(err => {
        errFn(reject, err)
      })
  })
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then(res => {
        successFn(resolve, res, reject)
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          console.log('cancel request')
        }
        errFn(reject, err)
      })
  })
}
const CancelToken = axios.CancelToken

/**
 * post方法，对应post请求可取消
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param {Function} cancel [回调创建 cancel token]
 */
export function postCancel(url, params, cancel) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, {
        cancelToken: new CancelToken(function executor(c) {
          cancel(c)
        }),
      })
      .then(res => {
        successFn(resolve, res, reject)
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          console.log('cancel request')
        }
        errFn(reject, err)
      })
  })
}
export function postStatus(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then(res => {
        if (res.data.status === 9001) {
          // if (isWxMiniProgram()) {
          //   navigateToMiniLogin()
          // } else {
          //   Router.push('/login')
          // }
          if (isWxMiniProgram()) {
            let redirectUrl = Router.currentRoute.fullPath
            console.log('9001')
            navigateToMiniLogin(true, encodeURIComponent(redirectUrl))
          } else {
            const isZdd = store.getters['system/isZdd']
            let redirectUrl = Router.currentRoute.fullPath
            if (isZdd && Router.currentRoute.name === 'List') {
              redirectUrl = '/zdd/home'
            }
            console.log('replace')
            Router.replace({
              path: '/login',
              query: {
                redirect: redirectUrl,
              },
            })
          }
          return
        }
        resolve(res.data)
      })
      .catch(err => {
        errFn(reject, err)
      })
  })
}

/**
 * post方法，参数序列化form Data形式
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function qspost(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, Qs.stringify(params))
      .then(res => {
        successFn(resolve, res)
      })
      .catch(err => {
        errFn(reject, err)
      })
  })
}

/**
 * post方法，参数序列化form Data形式
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function UploadPost(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(res => {
        successFn(resolve, res)
      })
      .catch(err => {
        errFn(reject, err)
      })
  })
}
