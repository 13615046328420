import env from '@/config/env'
/**
 * 通过router设置的title来设置 `document.title`
 */
function setTitle({ router, store }) {
  return async (to, from, next) => {
    document.title = (to.meta && to.meta.title) || env.title
    next()
  }
}

export default setTitle
