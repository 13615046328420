import { resetCookies } from '@/utils/cookies'

/**
 * 页面退出清除cookies
 * TODO:待定
 */
export default {
  install: async Vue => {
    // window.addEventListener('unload', async () => {
    //   await resetCookies();
    // });
    // window.addEventListener('beforeunload', async () => {
    //   await resetCookies();
    // });
  },
}
