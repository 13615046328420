import Apifiles from '@/api/files'
import setTheme from '@/utils/theme' // 设置主题色

function getSystemId() {
  const { host } = window.location

  let systemId = 0 // 0易云章 1题点 2职签 3职袋袋 4顺利办
  if (
    host === 'dev-zhiqian-h5.myrrx.com' ||
    host === 'fat-zhiqian-h5.myrrx.com' ||
    host === 'zqh5.xiaomajimi.com' ||
    host === 'h5.yizhiqian.cn'
  ) {
    systemId = 2
  } else if (
    host === 'dev-slb-h5.myrrx.com' ||
    host === 'fat-slb-h5.myrrx.com'
  ) {
    systemId = 4
  } else if (host === 'localhost:10010' || host === '192.168.100.32:10010') {
    // systemId = 3
  }
  return systemId
}

async function getChracter(systemId) {
  try {
    const model = { systemId }
    return await Apifiles.getChracter(model)
  } catch (error) {
    console.error(error)
  }
}

export async function initSystemInfo(store) {
  try {
    const systemId = getSystemId()
    const systemInfo = await getChracter(systemId)
    if (systemInfo.color) {
      setTheme({
        '--main-color': systemInfo.color,
      })
    }
    store.commit('system/setSystemInfo', {
      ...systemInfo,
      systemId,
    })
  } catch (error) {
    console.error(error)
  }
}
