import Vue from 'vue'
import { initSystemInfo } from '@/model/system'

// vant
import Vant from 'vant'
import '@vant/touch-emulator';
import Moment from 'moment'
import VConsole from 'vconsole'
import VueDraggableResizable from 'vue-draggable-resizable'
// plugins
import plugins from '@/plugins'
import 'amfe-flexible'
import App from './App.vue'
// mixins
import mixins from './mixins'
import '@/mixins/getImgUrl'

// router
import router from './router'
// store
import store from './store'
import background from './components/background.vue'

// styles
import './assets/reset.less'
import './style/index.less'
import 'vant/lib/index.less'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

// global js
import '@/global/WeChatInput'
import { miniLogin } from './utils/mini'
import { isWxMiniProgram } from './utils/ua'
import { isApiURL } from './utils/helper'

// if (process.env.VUE_APP_ENV !== 'production') {
//   const vConsole = new VConsole()
// }

Vue.config.ignoredElements = ['wx-open-launch-weapp']
Vue.use(plugins)
Vue.use(mixins)
Vue.use(Vant)
Vue.component('background', background)
Vue.component('vue-draggable-resizable', VueDraggableResizable)
Vue.prototype.$checkPhone = telephone => /^1[3456789]\d{9}$/.test(telephone)
Vue.prototype.$jsonPase = jsonString => {
  try {
    return JSON.parse(jsonString)
  } catch (error) {
    return {}
  }
}
Vue.filter('filterTime', value => {
  if (!value) return ''
  return Moment(value * 1000).format('YYYY-MM-DD')
})
Vue.prototype.$isWeiXin = () => {
  const ua = window.navigator.userAgent.toLowerCase()
  // 通过正则表达式匹配ua中是否含有MicroMessenger字符串
  if (ua.match(/MicroMessenger/i)[0] === 'micromessenger') {
    return true
  }
  return false
}

Vue.config.productionTip = false
Vue.prototype.$Bus = new Vue()

async function createVueApp() {
  if (isWxMiniProgram() && !isApiURL()) {
    miniLogin()
  }
  await initSystemInfo(store)
  storeState()
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
}
createVueApp()
function storeState() {
  
  // 在页面加载时读取sessionStorage
  if (sessionStorage.getItem('store')) {
    const stores = {
      ...store.state,
      ...JSON.parse(sessionStorage.getItem('store')),
    }
    
    store.replaceState(stores)
  }
  // 在页面刷新时将store保存到sessionStorage里
  window.addEventListener('pagehide', () => {
    const needStoreState = {
      ...store.state,
      // 不要存储user信息
      user: {
        userInfo: {
          authList: [],
          comId: undefined,
          comName: undefined,
          comCreditStatus: 0, // 企业认证
          createTime: undefined,
          creditStatus: undefined,
          creditTm: undefined,
          email: null,
          headImg: null,
          idCardNo: undefined,
          name: undefined,
          telephone: undefined,
          userId: undefined,
          isNewRegist: false,
          isSkipSetPassword: false,
          isCloseNoviceGuide: false, // zdd  是否完成选择身份
          leftUmber: 0,
        },
        myInfo: {
          companyInfoVoList: [], // 用户身份集合
          userSignList: [], // 个人签名集合
        },
        isComUser: false,
        addSign: false,
        scrollHeight: 0,
        loginCode: '',
      },
    }

    sessionStorage.setItem('store', JSON.stringify(needStoreState))
  })
}
