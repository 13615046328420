export default [
  {
    path: '/addSignName',
    name: 'addSignName',
    component: () => import('@/views/sign/addSignName.vue'),
    meta: {
      title: '添加签名',
      whiteList: true,
    },
  },
  {
    path: '/addSignConfirm',
    name: 'addSignConfirm',
    component: () => import('@/views/sign/addSignConfirm.vue'),
    meta: {
      title: '加入签署',
      whiteList: true,
    },
  },
  {
    path: '/addSignNoConfirm',
    name: 'addSignNoConfirm',
    component: () => import('@/views/sign/addSignNoConfirm.vue'),
    meta: {
      title: '加入签署',
      whiteList: true,
    },
  },
  {
    path: '/addSign',
    name: 'addSign',
    component: () => import('@/views/sign/addSign.vue'),
    meta: {
      title: '加入签署',
      whiteList: true,
    },
  },
  {
    path: '/addSignError',
    name: 'addSignError',
    component: () => import('@/views/sign/addSignError.vue'),
    meta: {
      title: '加入签署',
      whiteList: true,
    },
  },
  {
    path: '/addSignError2',
    name: 'addSignError2',
    component: () => import('@/views/sign/addSignError2.vue'),
    meta: {
      title: '加入签署',
      whiteList: true,
    },
  },
  {
    path: '/addSignSuccess',
    name: 'addSignSuccess',
    component: () => import('@/views/sign/addSignSuccess.vue'),
    meta: {
      title: '加入签署',
    },
  },
  {
    path: '/signSuccessPage',
    name: 'signSuccessPage',
    component: () => import('@/views/sign/signSuccessPage.vue'),
    meta: {
      title: '签署完成',
    },
  },
]
