import { get, post } from '../axios'

export default {
  /**
   * 查询业务详情
   */
  getTemplateBusiness(params = {}) {
    return get('/core/templateBusiness/getTemplateBusiness', params)
  },
}
