import SvgIcon from '@/components/SvgIcon.vue'

const components = [SvgIcon]
export default {
  install: Vue => {
    components.forEach(component => {
      Vue.component(component.name, component)
    })
  },
}
