export default [
  {
    path: '/my',
    name: 'My',
    component: () => import('@/views/my/my'),
    meta: {
      title: '我的',
      navShow: true,
    },
  },
  {
    path: '/myInfo',
    name: 'MyInfo',
    component: () => import('@/views/my/myInfo'),
    meta: {
      title: '个人信息',
    },
  },
  {
    path: '/switchStatus',
    name: 'SwitchStatus',
    component: () => import('@/views/my/switchStatus'),
    meta: {
      title: '切换身份',
    },
  },
  {
    path: '/createCompany',
    name: 'CreateCompany',
    component: () => import('@/views/my/createCompany'),
    meta: {
      title: '创建企业',
    },
  },
  {
    path: '/authSubmit',
    name: 'AuthSubmit',
    component: () => import('@/views/my/authCompany/authSubmit'),
    meta: {
      title: '信息提交',
    },
  },
  {
    path: '/authConfirm',
    name: 'AuthConfirm',
    component: () => import('@/views/my/authCompany/authConfirm'),
    meta: {
      title: '认证授权',
    },
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('@/views/my/account'),
    meta: {
      title: '我的账户',
    },
  },
  {
    path: '/payGuide',
    name: 'payGuide',
    component: () => import('@/views/my/payGuide'),
    meta: {
      title: '支付',
      whiteList: true,
    },
  },
  {
    path: '/payStatus',
    name: 'payStatus',
    component: () => import('@/views/my/payStatus'),
    meta: {
      title: '支付',
    },
  },
  {
    path: '/signManage',
    name: 'signManage',
    component: () => import('@/views/my/signManage'),
    meta: {
      title: '签名管理',
    },
  },
  {
    path: '/sealManage',
    name: 'sealManage',
    component: () => import('@/views/my/sealManage/sealManage'),
    meta: {
      title: '印章管理',
    },
  },
  {
    path: '/addSeal',
    name: 'addSeal',
    component: () => import('@/views/my/sealManage/addSeal'),
    meta: {
      title: '在线制作印章',
    },
  },
  {
    path: '/qywxAccountActivation',
    name: 'qywxAccountActivation',
    component: () => import('@/views/my/qywxAccountActivation'),
    meta: {
      title: '账号激活',
    },
  },
]
