const env = {
  // 环境模式
  mode: '',

  // 应用名称
  name: '',

  // 网站标题
  title: '',

  // oss 网址
  ossUrl: '',
}
env.mode = process.env.NODE_ENV
env.name = process.env.VUE_APP_NAME
env.title = process.env.VUE_APP_TITLE
env.ossUrl = process.env.VUE_APP_OSS_URL

export default env
