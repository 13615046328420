export default function(styleObject) {
  const docStyle = document.documentElement.style
  // eslint-disable-next-line no-restricted-syntax
  for (const key in styleObject) {
    // eslint-disable-next-line no-prototype-builtins
    if (styleObject.hasOwnProperty(key)) {
      docStyle.setProperty(key, styleObject[key])
    }
  }
}
