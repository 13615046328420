import Vue from 'vue'
import env from '@/config/env'

Vue.mixin({
  methods: {
    /**
     * 根绝图片的key拼接整全部的图片地址
     * @param {string} key 图片的key
     */
    getImgUrl(key) {
      return key
    },
  },
})
