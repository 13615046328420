import { get, post } from './axios'

export default {
  // 查询印章列表
  getSealList(param) {
    return get('/user/seal/getSealList', param)
  },
  // 对印章启用、停用或删除所有人操作
  updateSealStatus(param) {
    return post('/user/seal/updateSealStatus', param)
  },
  // 查询印章关联信息
  getSealRelation(param) {
    return get('/core/contractBusiness/getSealRelation', param)
  },
  // 查询印章详情
  getSealDetail(param) {
    return get('/user/seal/getSealDetail', param)
  },
  // 查询印章默认持有人
  getDefaultAdminList(param) {
    return get('/user/seal/getDefaultAdminList', param)
  },
  // 在线制作印章
  makeSealOnline(param) {
    return post('/user/seal/makeSealOnline', param)
  },
  // 添加印章所属角色
  addSealRoleAuth(param) {
    return post('/user/seal/addSealRoleAuth', param)
  },
  // 查询印章列表
  getSealListByStatus(param) {
    return get('/user/seal/getSealListByStatus', param)
  },
  // 查询印章关联信息，获取印章图片
  getUserSignature(param) {
    return get('/user/seal/getUserSignature', param)
  },

  // 批量制作印章，用于在企业认证成功后批量创建印章
  makeBatchSealOnline(param) {
    return post('/user/seal/makeBatchSealOnline', param)
  },
}
