import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import addMiddleWares from './middleware'
import addressList from './addressList'
import launch from './launch'
import auth from './auth'
import sign from './sign'
import signFile from './signFile'
import signFileOut from './signFileOut'
import list from './list'
import my from './my'
import zdd from './zdd'
import mini from './mini'
import publish from './publish'
import homepage from './homepage'

const Home = () => import('@/views/Home')
const Login = () => import('@/views/Login/Login')
const Register = () => import('@/views/Login/Register')
const SetPassword = () => import('@/views/Login/SetPassword')
const Download = () => import('@/views/Download/Download')
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    redirect: { name: 'Home' },
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    meta: {
      whiteList: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: '登录',
      whiteList: true,
    },
  },
  {
    path: '/init',
    name: 'Init',
    component: () => import('@/views/init/index'),
    meta: {
      whiteList: true,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      title: '注册',
      whiteList: true,
    },
  },
  {
    path: '/setPassword',
    name: 'SetPassword',
    component: SetPassword,
    meta: {
      title: '设置密码',
      whiteList: true,
    },
  },
  {
    path: '/download',
    name: 'Download',
    component: Download,
    meta: {
      title: '下载文件',
    },
  },
  ...addressList,
  ...launch,
  ...auth,
  ...sign,
  ...signFile,
  ...signFileOut,
  ...list,
  ...my,
  ...zdd,
  ...mini,
  ...publish,
  ...homepage,
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  },
})

addMiddleWares({ router, store })
VueRouter.prototype.goBack = function () {
  this.isBack = true
  this.go(-1)
}

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
