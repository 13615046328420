import { get, post, postCancel } from '../axios'

export default {
  // 上传文件
  uploadFile(params, cancel) {
    return postCancel('/core/templateFile/uploadFile', params, cancel)
  },
  // 上传文件
  async uploadFileToSaaS(params, cancel) {
    let timer = null
    const res = await postCancel(
      '/core/newTemplateFile/uploadFileToSaaS',
      params,
      cancel
    )
    return new Promise((resolve, reject) => {
      let loading = false
      try {
        if (res) {
          if (res.taskId) {
            timer = setInterval(async () => {
              try {
                if (loading) return
                const model = res
                loading = true
                const imgList = await this.getPdfInfo(model)
                loading = false
                if (imgList) {
                  clearInterval(timer)
                  timer = null
                  resolve({
                    ...res,
                    imgList,
                    fileUrl: imgList && imgList[0],
                  })
                }
              } catch (error) {
                loading = false
                clearInterval(timer)
                timer = null
                reject(error)
              }
            }, 2000)
          } else {
            resolve(res)
          }
        }
      } catch (error) {
        loading = false
        reject(error)
        timer = null
        clearInterval(timer)
        console.error('上传文件错误', error)
      } finally {
        loading = false
      }
    })
  },

  getPdfInfo(param) {
    return post('/core/newTemplateFile/getPdfInfo', param)
  },

  /**
   * 上传文件
   */
  getImageList(params) {
    return get('/core/newTemplateFile/getImageList', params)
  },
  /**
   * 微信小程序上传文件后这里要获取文件信息
   */
  uploadFileToApplet(params) {
    return post('/core/templateFile/uploadFileToApplet', params)
  },

  // 获取变量模版
  getHomePageClassifyInfoList(param) {
    return post('core/templateBusiness/getHomePageClassifyInfoList', param)
  },
}
