export default [
  {
    path: '/list',
    name: 'List',
    component: () => import('@/views/list/index'),
    meta: {
      title: '文件列表',
      navShow: true,
      // keepAlive: true,
    },
  },
]
