import { SIGN_SEAL_TYPE } from '@/common/signEnum'

export const userSignatureDefaultWidth = 102
export const userSignatureDefaultHeight = 37

/**
 * 属于个人签名的 签章类型
 *
 * @type {(number)[]}
 */
export const userSignatureSignType = [
  SIGN_SEAL_TYPE.PERSONAL_SEAL,
  SIGN_SEAL_TYPE.HANDLER_SEAL,
  SIGN_SEAL_TYPE.LEGAL_PERSON_SEAL,
]

/**
 * 判断当前的签章类型是否为 个人签名类型
 *
 * @param signType
 * @return {boolean}
 */
export const isUserSignType = signType => {
  return userSignatureSignType.includes(signType)
}
