import cloneDeep from 'lodash.clonedeep'

export default {
  namespaced: true,
  state: {
    simpleDefaultModel: {
      titleName: undefined,
      businessId: undefined,
      fileList: [],
      launchSign: 0,
      masterId: undefined,
      noCreditSign: 0,
      partnerList: [],
      validDay: 30,
    },
    simpleLaunchModel: {},
    progressFile: [], // 上传临时文件
  },
  mutations: {
    setPartnerId(state, id) {
      state.partnerId = id
    },

    /**
     * 设置极简发起model的值
     * key 是当前的随机key
     * obj 是当前 key 对应的 model 数据
     * @param {*} state
     * @param {*} param1
     */
    setSimpleLaunchModel(state, { key, obj }) {
      state.simpleLaunchModel[key] = obj
    },
    setProgressFile(state, file) {
      /** 过滤掉emoji表情 */
    if(file.fileName){
      /** 配置所有emoji的正则 */
      let reg = /[\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]|\uD83E[\uDD00-\uDDFF]|\u2600-\u27BF]/g;
      const name = file.fileName.replace(reg, '');
      file.fileName = name
    }
      state.progressFile.push(file)
    },
    delProgressFile(state) {
      state.progressFile.splice(0, 1)
    },
    clearProgressFileList(state, file) {
      state.progressFile = []
    },
  },

  getters: {
    getSimpleDefaultModel(state) {
      return cloneDeep(state.simpleDefaultModel)
    },
    simpleLaunchModel(state) {
      return state.simpleLaunchModel
    },
    getProgressFile(state) {
      return state.progressFile
    },
  },
}
