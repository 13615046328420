<template>
  <div
    v-if="isExternal"
    v-bind="$attrs"
    :style="styleExternalIcon"
    class="svg-external-icon svg-icon"
    v-on="$listeners"
  />
  <i v-else v-bind="$attrs" class="anticon" v-on="$listeners">
    <svg class="svg-icon" aria-hidden="true" focusable="false">
      <use :xlink:href="iconName" />
    </svg>
  </i>
</template>

<script>
  function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
  }

  export default {
    name: 'SvgIcon',
    props: {
      type: {
        type: String,
        required: true,
      },
    },
    computed: {
      isExternal() {
        return isExternal(this.type)
      },
      iconName() {
        return `#icon-${this.type}`
      },
      styleExternalIcon() {
        return {
          mask: `url(${this.type}) no-repeat 50% 50%`,
          '-webkit-mask': `url(${this.type}) no-repeat 50% 50%`,
        }
      },
    },
  }
</script>

<style scoped>
  .svg-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
  }
  .svg-external-icon {
    background-color: currentColor;
    mask-size: cover !important;
    display: inline-block;
  }
</style>
