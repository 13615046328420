import { onKeyBoardPopup } from '@/utils/helper'

export default {
  // mounted() {
  //   this.$nextTick(() => {
  //     const inputs = document.querySelectorAll('input')
  //     inputs.forEach(input => {
  //       onKeyBoardPopup(input)
  //     })
  //   })
  // },
}
