import { getBodyClientRect } from '@/utils/dom'
import { reTranslateSealWidthAndHeight } from './pxtorem'

const signNameType = [0, 1, 2]
const signSealType = [3, 4]

/**
 * 获取签章图片的宽高
 * @param {string} imgSrc
 */
export const getSealRect = imgSrc => {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img')
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      })
    }
    img.src = imgSrc
  })
}

/**
 * 获取签章位置中心点，用来在后端生成合同用
 * @param {object[]} positionList 签章list
 */
export const getMiddlePositionList = async positionList => {
  const { clientWidth } = getBodyClientRect()
  const promiseList = positionList.map(async sign => {
    let h = 93
    sign.changeFlag = 0
    // fix: 解决个别用户没有wrapWidth字段后续无法计算的问题
    if (!sign.wrapWidth) {
      sign.wrapWidth = clientWidth
    }
    if (sign.sealImg) {
      const sealImgUrl = sign.sealImg
      const { height, width } = await getSealRect(sealImgUrl)
      if (sign.width === 93 && sign.height === 93) {
        h = 93
      } else {
        h = sign.height ? sign.height : height
      }
      // 印章 changeFlag = 0
      if (!(sign.height === h && sign.width === width) && sign.signType !== 3) {
        // 是否拉伸
        sign.changeFlag = 1
      }
      // 如果为骑缝章
      if (sign.signType === 4) {
        sign.changeFlag = 0
      }
    } else {
      sign.sealImg = sign.sealKey
      if (!(sign.height === 93 && sign.width === 93)) {
        // 是否拉伸
        if (sign.signType !== 3) {
          sign.changeFlag = 1
        }
        h = sign.height ? sign.height : 93
      }
    }
    if (sign.changeFlag === 1 && !sign.skipTranslate) {
      h = reTranslateSealWidthAndHeight(sign.wrapWidth, h)
    }
    // console.log('signHeight', h)
    // const offsetY = height / 2
    const offsetY = h
    if (signNameType.includes(sign.signType)) {
      // sign.x += offsetX
      sign.y += offsetY
    }
    if (signSealType.includes(sign.signType)) {
      // sign.x += signSealOffSetX
      sign.y += offsetY
    }
    console.log('sign---', sign)
    return sign
  })
  const resList = await Promise.all(promiseList)
  return resList
}

/**
 * 签章起始位置 即左上角的位置 用来在页面上定位展示用
 * @param {object[]} positionList
 */
export const getStartPositionList = async positionList => {
  // console.log('positionList', positionList)
  const promiseList = await positionList.map(async sign => {
    const { id } = sign
    // const sealImgUrl = getSignImg(id)
    let h = sign.height ? sign.height : 93
    if (sign.sealImg) {
      const sealImgUrl = sign.sealImg

      const { height } = await getSealRect(sealImgUrl)
      if (sign.width === 93 && sign.height === 93) {
        h = 93
      } else {
        h = sign.height ? sign.height : height
      }
    } else {
      sign.sealImg = sign.sealKey
    }
    const offsetY = h
    // const offsetY = height / 2
    if (signNameType.includes(sign.signType)) {
      // sign.x -= offsetX
      sign.y -= offsetY
    }

    if (signSealType.includes(sign.signType)) {
      sign.y -= offsetY
    }
    return sign
  })
  const resList = await Promise.all(promiseList)
  return resList
}

/**
 * 将移动端展示的宽高转换为和pc端同步的签署时真实的宽高
 * @param {Array} positionList - 签章位置 list
 * @param {Number} wrapWidth - 容器的宽度
 * @return {*}
 */
export function reTranslateWidthAndHeightList(positionList) {
  return positionList.map(sign => {
    if (signNameType.includes(sign.signType)) {
      // 如果是签名才需要转换，因为签名会放大缩小
      const { width, height, wrapWidth } = sign
      // 判断签名发生了缩放在去转换
      if (sign.changeFlag && !sign.skipTranslate) {
        sign.width = reTranslateSealWidthAndHeight(wrapWidth, width)
        sign.height = reTranslateSealWidthAndHeight(wrapWidth, height)
      }
    }
    return sign
  })
}
