const ua = navigator.userAgent
export const isIos = () => {
  const u = navigator.userAgent
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
}

export const isAndroid = () =>
  navigator.userAgent.indexOf('Android') > -1 ||
  navigator.userAgent.indexOf('Adr') > -1

export const isWeiXin = () => !!ua.match(/MicroMessenger/gi)

export const isMobile = () => {
  return ua.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
}

/**
 * 是否为微信小程序环境
 *
 * @returns {boolean}
 */
export const isWxMiniProgram = () => {
  return ua.match(/mini[Pp]rogram/)
}

/**
 * 是否为头条小程序环境
 *
 * @returns
 */
export const isTTMiniProgram = () =>
  navigator.userAgent.toLowerCase().includes('toutiaomicroapp')

/**
 * 是否是在小程序环境下(包含微信小程序、头条小程序)
 */
export const isMiniProgram = () => {}
