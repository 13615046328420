const SignDetail = () => import('@/views/signFile/SignDetail')
const FileDetail = () => import('@/views/signFile/FileDetail')
const SignComplete = () => import('@/views/signFile/SignComplete')

export default [
  {
    path: '/signFile',
    name: 'signFile',
    component: () => import('@/views/signFile/signFile.vue'),
    meta: {
      title: '文件签署',
    },
  },
  {
    path: '/catFile',
    name: 'catFile',
    component: () => import('@/views/signFile/catFile.vue'),
    meta: {
      title: '文件签署',
    },
  },
  {
    path: '/files/detail',
    name: 'fileDetail',
    component: FileDetail,
    meta: {
      title: '签署详情',
    },
  },
  {
    path: '/signdetail',
    name: 'SignDetail',
    component: SignDetail,
    meta: {
      title: '签署详情',
    },
  },
  {
    path: '/signcomplete',
    name: 'SignComplete',
    component: SignComplete,
    meta: {
      title: '签署完成',
    },
  },
  {
    path: '/signEvidence',
    name: 'signEvidence',
    component: () => import('@/views/signFile/signEvidence.vue'),
    meta: {
      title: '查看存证报告',
    },
  },
  {
    path: '/updatePWD',
    name: 'updatePWD',
    component: () => import('@/views/signFile/updatePWD.vue'),
    meta: {
      title: '交易密码',
    },
  },
]
