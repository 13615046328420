import store from '@/store'
import { hasAuth } from '@/utils/hasAuth'
import router from '@/router'

const isCom = () => store.getters.isComUser
const hasFinanceAuth = () => hasAuth(['finance_view'])

export default {
  install(Vue) {
    Vue.prototype.$showChargeDialog = async (vm, query) => {
      // 企业身份
      if (isCom()) {
        // 判断有无财务权限
        if (hasFinanceAuth()) {
          await vm.$dialog.alert({
            title: '余额不足',
            message: '您的账户剩余份数不足，暂不能发起，请先充值',
            confirmButtonText: '去充值',
            showCancelButton: '取消',
          })

          await router.push({
            name: 'account',
            query,
          })
        } else {
          await vm.$dialog.alert({
            title: '余额不足',
            message:
              '您的企业账户剩余份数不足，暂不能发起，请联系管理员充值，建议您保存草稿',
            confirmButtonText: '知道了',
          })
        }
      } else {
        await vm.$dialog.alert({
          title: '余额不足',
          message: '您的账户剩余份数不足，暂不能发起，请先充值',
          confirmButtonText: '去充值',
          showCancelButton: true,
          cancelButtonText: '取消',
        })
        await router.push({
          name: 'account',
          query,
        })
      }
    }
  },
}
