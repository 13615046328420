import { setCookie } from './cookies'

/**
 * 从小程序过来的登录逻辑
 */
export function miniLogin() {
  // const url = new URL(decodeURIComponent(window.location))
  // const from = url.searchParams.get('from')
  // const session = url.searchParams.get('userId')
  // if (session) {
  //   const [userIdValue, userInfo] = session.split(';')
  //   const [, userInfoKey, userInfoValue] = userInfo.split(/([^=?&]+)=([^?&]+)/)
  //   if (userIdValue) {
  //     setCookie('userId', userIdValue)
  //   }
  //   if (userInfoValue) {
  //     // 截取出来的 cookie 结尾少一个 分号，手动加上
  //     setCookie('userInfo', decodeURIComponent(`${userInfoValue};`))
  //   }
  // }

  const searchValue = decodeURIComponent(window.location.search).substring(1)
  console.log('searchValue', searchValue)
  if (searchValue) {
    const searchQuery = {}
    const arr = searchValue.split('&')
    arr.reduce((memo, curr) => {
      const [key, value] = curr.split('=')
      if (key === 'userInfo') {
        searchQuery[key] = `${value}==;`
      } else {
        searchQuery[key] = value
      }
      return searchQuery
    }, searchQuery)

    console.log('searchQuery--', searchQuery)

    const { userId, userInfo } = searchQuery
    if (userId) {
      setCookie('userId', userId)
    }
    if (userInfo) {
      setCookie('userInfo', userInfo)
    }

    // const [from, session] = searchValue.split('&')
    // console.log(from, session)
    // const [userId, userInfo] = session.split(';')
    // const [userIdKey, userIdValue] = userId.split('=')
    // const [, userInfoKey, userInfoValue] = userInfo.split(/([^=?&]+)=([^?&]+)/)
    // setCookie('userId', userIdValue)
    // setCookie('userInfo', userInfoValue)
  }

  // const reg = /([^&?=]+)=([^&?=]+)/g
  // const matchResults = searchValue.match(reg)
  // console.log(matchResults)
  // matchResults.reduce((prev, cur) => {
  //   const [key, value] = cur.split('=')
  //   if (key === 'userId' || key === 'userInfo') {
  //     const decodeValue = decodeURIComponent(value)
  //     prev[key] = decodeValue
  //
  //   }
  //   return prev
  // }, {})
}

let navigateToMiniLoginPending = false
/**
 * 当前未登录，跳转到小程序去登录
 *
 * @param {boolean} replace - 默认是 `replace` 否则是 `push`
 * @param {url} redirectUrl - 默认回跳的地址
 */
export function navigateToMiniLogin(replace = true, redirectUrl) {
  // 小程序环境，可以直接跳转到登录页面
  if (navigateToMiniLoginPending) return
  console.log('跳转mini登录')
  navigateToMiniLoginPending = true
  if (replace) {
    window.wx.miniProgram.redirectTo({
      url: `/pages/login/login?from=h5&redirectUrl=${redirectUrl}`,
      complete: () => {
        navigateToMiniLoginPending = false
      },
    })
  } else {
    window.wx.miniProgram.navigateTo({
      url: `/pages/login/login?from=h5&redirectUrl=${redirectUrl}`,
      complete: () => {
        navigateToMiniLoginPending = false
      },
    })
  }
}

/**
 * 跳转到小程序发起
 */
export function navigateToMiniLaunch() {
  window.wx.miniProgram.navigateTo({
    url: '/pages/index/index',
  })
}

/**
 * 与小程序进行通信
 *
 * @param {*} data
 */
export function postMessageToMini(data) {
  window.wx.miniProgram.postMessage({
    data,
  })
}

/**
 * 与小程序通信分享
 *
 * @param {*} data
 */
export function postShareMessageToMini(data) {
  postMessageToMini({
    type: 'share',
    ...data,
  })
}
