export default [
  {
    path: '/auth',
    name: 'auth',
    component: () => import('@/views/auth/auth.vue'),
    meta: {
      title: '选择方式',
      whiteList: true,
    },
  },
  {
    path: '/telephone',
    name: 'telephone',
    component: () => import('@/views/auth/telephone.vue'),
    meta: {
      title: '个人身份认证服务',
      whiteList: true,
    },
  },
  {
    path: '/bankCard',
    name: 'bankCard',
    component: () => import('@/views/auth/bankCard.vue'),
    meta: {
      title: '个人身份认证服务',
      whiteList: true,
    },
  },
  {
    path: '/nameAndIdentitycard',
    name: 'nameAndIdentitycard',
    component: () => import('@/views/auth/nameAndIdentitycard.vue'),
    meta: {
      title: '个人身份认证服务',
      whiteList: true,
    },
  },
  {
    path: '/faceverifying',
    name: 'FaceVerifying',
    component: () => import('@/views/auth/faceVerifying.vue'),
    meta: {
      title: '认证中',
      whiteList: true,
    },
  },
  {
    path: '/faceRecognition',
    name: 'faceRecognition',
    component: () => import('@/views/auth/faceRecognition.vue'),
    meta: {
      title: '个人身份认证服务',
      whiteList: true,
    },
  },
  {
    path: '/authenticationError',
    name: 'authenticationError',
    component: () => import('@/views/auth/authenticationError.vue'),
    meta: {
      title: '个人身份认证服务',
      whiteList: true,
    },
  },
  {
    path: '/authenticationConfirm',
    name: 'authenticationConfirm',
    component: () => import('@/views/auth/authenticationConfirm.vue'),
    meta: {
      title: '实名认证',
      whiteList: true,
    },
  },
  {
    path: '/authenticationSuccess',
    name: 'authenticationSuccess',
    component: () => import('@/views/auth/authenticationSuccess.vue'),
    meta: {
      title: '个人身份认证服务',
      whiteList: true,
    },
  },
  {
    path: '/signCreaditBook',
    name: 'signCreaditBook',
    component: () => import('@/views/auth/signCreaditBook.vue'),
    meta: {
      title: '签署授权书',
      whiteList: true,
    },
  },
  {
    path: '/signSuccess',
    name: 'signSuccess',
    component: () => import('@/views/auth/signSuccess.vue'),
    meta: {
      title: '签署授权书',
      whiteList: true,
    },
  },
  {
    path: '/signError',
    name: 'signError',
    component: () => import('@/views/auth/signError.vue'),
    meta: {
      title: '签署授权书',
      whiteList: true,
    },
  },
  {
    path: '/signAuth',
    name: 'signAuth',
    component: () => import('@/views/auth/signAuth.vue'),
    meta: {
      title: '个人身份认证服务',
      whiteList: true,
    },
  },
]
