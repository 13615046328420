export default {
  props: {
    appendToBody: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.appendToBody) {
      document.body.appendChild(this.$el)
      this.$on('hook:beforeDestroy', () => {
        if (this?.$el?.parentNode) {
          this.$el.parentNode.removeChild(this.$el)
        }
      })
    }
  },
}
