import Api from '@/api/contractSign'
import sealApi from '@/api/seal'
import store from '@/store'
import {
  getMiddlePositionList,
  getStartPositionList,
  reTranslateWidthAndHeightList,
} from '@/utils/handleSealPosition'
import cloneDeep from 'lodash.clonedeep'
import getSealRect from '@/utils/getSealRect'
import {
  isUserSignType,
  userSignatureDefaultHeight,
  userSignatureDefaultWidth,
} from '@/common/userSignatureConfig'
import Moment from 'moment'
import { formatDate } from '@/common/dateFormatOptions'
import myApi from '@/api/my'

const userId = () => store.getters.getUserInfo.userId
const isComUser = () => store.getters.isComUser

async function getSealList() {
  try {
    return await sealApi.getSealListByStatus()
  } catch (error) {
    console.error(error)
  }
}

/**
 * 获取第一个待处理的文件
 * @param {Array<Object>} fileList - 当前操作的文件列表
 */
function getFirstFileToBeProcess(fileList) {
  // 未处理的文件的subStatus 0.待填写 1.待审批 2.待签署 3.待签章
  const unOperateStatusList = [0, 1, 2, 3]
  return fileList.find(file => unOperateStatusList.includes(file.status))
}

export default {
  namespaced: true,
  state: {
    loading: false,
    agreeSign: true,
    isFirstAgreeSign: true,
    ratio: 343 / 595,
    ratio2: 1,
    computedY: 485,
    fileLoading: false,
    showSignPassword: false,
    scrollTop: 0,
    subId: null,
    checkedSealType4: null,
    checkedSealType: null,
    pageNum: 1,
    signType4: false,
    currentFileIndex: 0,
    approveResult: null,
    operateType: null,
    status: null,
    showCom: 'SignVar',
    currentFile: {},
    fileList: [],
    pwdErrMsg: null,
    isNullifyFalg: null,
  },
  mutations: {
    setNullifyFalg(state, payload) {
      state.isNullifyFalg = payload
    },
    setRatio(state, payload) {
      state.ratio = payload / 595
      state.computedY = (payload / 595) * 842
    },
    setRatio2(state, payload) {
      state.ratio2 = payload
    },
    setShowSignPassword(state, payload) {
      state.showSignPassword = payload
      state.pwdErrMsg = null
    },
    setIsFirstAgreeSign(state, payload) {
      state.isFirstAgreeSign = payload
    },
    setCheckedSealType4(state, payload) {
      state.checkedSealType4 = payload
    },
    setCheckedSealType(state, payload) {
      state.checkedSealType = payload
    },
    setAgreeSign(state, payload) {
      state.agreeSign = payload
    },
    setScrollTop(state, payload) {
      state.scrollTop = payload
    },
    addSignData(state) {
      state.currentFile.signData.push({
        signList: [],
        signPwd: null,
        subId: null,
        verifyCode: null,
        verifyType: 1, // 验证方式 1.短信验证码 2.签署密码
      })
    },
    setSignType4(state, payload) {
      state.signType4 = payload
    },
    removeSignType4(state) {
      state.signType4 = false
      let { currentPositionList } = state.currentFile
      if (currentPositionList && currentPositionList.length !== 0) {
        currentPositionList = currentPositionList.filter(
          item => item.signType !== 4
        )
        return
      }
      const { signData } = state.currentFile
      signData.signList = signData.signList.filter(item => item.signType !== 4)
    },
    setApproveResult(state, payload) {
      state.approveResult = payload
    },
    resetData(state) {
      state = Object.assign(state, {
        loading: false,
        agreeSign: true,
        isFirstAgreeSign: true,
        ratio: 343 / 595,
        fileLoading: false,
        showSignPassword: false,
        scrollTop: 0,
        subId: null,
        checkedSealType4: null,
        checkedSealType: null,
        pageNum: 1,
        signType4: false,
        currentFileIndex: 0,
        approveResult: null,
        operateType: null,
        status: null,
        showCom: '',
        currentFile: {},
        fileList: [],
        // 是否指定章
        assignId: '',
        // 参与方来源类型：0.指定人/公司 1.指定章 2.企业角色 3.关系角色 4.法人 5.发起人
        assignType: '',
      })
    },
    setFileData(state, payload) {
      state.fileData = payload
    },
    setCurrentFileIndex(state, payload) {
      state.currentFileIndex = payload
    },
    setCurrentFile(state, payload) {
      state.currentFile = payload
    },
    openLoading(state, key) {
      state[key] = true
    },
    closeLoading(state, key) {
      state[key] = false
    },
    addSeal(
      state,
      {
        x,
        y,
        sealId,
        signType,
        sealKey,
        sealImg,
        page,
        dateFlag,
        wrapWidth,
        width,
        height,
        skipTranslate,
      }
    ) {
      state.currentFile.signData.signList.push({
        signType,
        sealKey,
        sealImg,
        sealId,
        page,
        x,
        y,
        dateFlag,
        width,
        height,
        wrapWidth,
        skipTranslate,
        userId: signType === 0 ? userId() : undefined,
      })
    },
    setCurrentPosition(
      state,
      { index, x, y, page, width, height, wrapWidth, changeFlag }
    ) {
      state.currentFile.currentPositionList[index].x = x
      state.currentFile.currentPositionList[index].y = y
      state.currentFile.currentPositionList[index].page = page
      if (changeFlag != null) {
        state.currentFile.currentPositionList[index].changeFlag = changeFlag
      }
      if (width) {
        state.currentFile.currentPositionList[index].width = width
      }
      if (height) {
        state.currentFile.currentPositionList[index].height = height
      }
      if (wrapWidth) {
        state.currentFile.currentPositionList[index].wrapWidth = wrapWidth
      }
    },
    setSealPosition(state, { x, y, index, page, width, height, wrapWidth }) {
      state.currentFile.signData.signList[index].x = x
      state.currentFile.signData.signList[index].y = y
      state.currentFile.signData.signList[index].page = page
      if (width) {
        state.currentFile.signData.signList[index].width = width
      }
      if (height) {
        state.currentFile.signData.signList[index].height = height
      }
      if (wrapWidth) {
        state.currentFile.signData.signList[index].wrapWidth = wrapWidth
      }
    },
    deteleSeal(state, index) {
      state.currentFile.signData.signList.splice(index, 1)
    },
    setShowType(state, { subId, operateType, status, pageNum }) {
      state.subId = subId
      state.operateType = operateType
      state.status = status
      state.pageNum = pageNum
    },
    setShowCom(state, com) {
      state.showCom = com
    },
    /**
     * 是否设置指定章、获取指定章
     * @param {*} state
     * @param {*} assign
     */
    setAssignId(state, assign) {
      const { assignType, assignId } = assign
      state.assignType = assignType
      state.assignId = assignId
    },
    setErrMsg(state, key) {
      if (state.showSignPassword) {
        state.pwdErrMsg = key
      } else {
        state.pwdErrMsg = null
      }
    },
  },
  actions: {
    async addEmpowerFile({ state }, payload) {
      try {
        const isCom = isComUser()
        let width = 93
        let height = 93
        if (isCom) {
          // 企业身份
          const sealList = await getSealList()
          if (sealList && sealList.length) {
            const seal = sealList[0]
            const res = await getSealRect(seal.sealUrl)
            width = res.width
            height = res.height
          }
        } else {
          // 个人身份 就默认 93
        }

        let model = {
          width,
          height,
        }

        // 发起的时候需要传 partnerIndex
        if (payload && payload.partnerIndex) {
          model = {
            ...model,
            partnerIndex: payload.partnerIndex,
          }
        } else {
          // 签署的时候要传 subId
          model = {
            ...model,
            subId: state.subId,
          }
        }
        return await Api.addEmpowerFile(model)
      } catch (error) {
        console.error(error)
      }
    },
    // 切换文件
    changeFile({ commit, state, dispatch }, { fileData, isChange = false }) {
      // status 操作状态0.待填写 1.待审批 2.待签署 3.待签章 10.已填写 11.已审批 12.已签署 13.已签章
      // operateType 参与方操作类型.0-个人审批 1-个人签署 2-经办人签署 3-法人签署 4-企业签章 5-仅填写变量
      const { subId, status, operateType, pageNum } = fileData
      // 设置当前文件index
      commit('setShowType', {
        subId,
        operateType,
        status,
        pageNum,
      })
      const currentIndex = state.fileList.findIndex(
        item => item.subId === subId
      )
      if (currentIndex !== state.currentIndex) {
        commit('setSignType4', false)
      }
      commit('setCurrentFile', fileData)
      commit('setCurrentFileIndex', currentIndex)
      if (status === 0) {
        commit('setShowCom', 'SignVar')
        return
      }
      if (status === 1) {
        commit('setShowCom', 'FileApprova')
        return
      }
      if (status === 2 || status === 3) {
        commit('setShowCom', 'SignSeal')
        return
      }
      commit('setShowCom', 'CatFile')
      if (isChange) return
      const currentIndex2 = state.fileList.findIndex(item =>
        [0, 1, 2, 3].includes(item.status)
      )
      if (currentIndex2 === -1) return
      dispatch('changeFile', {
        fileData: state.fileList[currentIndex2],
        isChange: false,
      })
    },

    // 切换到第一个待处理的文件
    changeToFirstFileToBeProcess({ dispatch, state }) {
      const firstFileToBeProcess = getFirstFileToBeProcess(state.fileList)
      dispatch('changeFile', {
        fileData: firstFileToBeProcess || state.fileList[0],
        isChange: false,
      })
    },
    // 填写文件应用变量
    addVariable({ commit, state, dispatch }, params = {}) {
      const { subId, currentFile } = state
      const varList = currentFile.varList.map(item => {
        const { idStr, varTitle, varValue } = item
        return {
          idStr,
          varTitle,
          varValue,
        }
      })
      // commit('openLoading', 'loading')
      return Api.addVariable({
        subId,
        varList,
        ...params,
      })
        .then(res => {
          commit('setShowSignPassword', false)
          if (params.onlyCheck) {
            return
          }
          if (params.cb) {
            params.cb()
            return
          }
          dispatch('getFileList', {
            subId: state.subId,
            isInit: false,
          })
        })
        .finally(() => {
          // commit('closeLoading', 'loading')
        })
    },
    // 填写文件签章
    async addSignAndDate({ commit, state, dispatch }, params = {}) {
      try {
        const { ratio } = state
        const { subId, signData, currentPositionList } = state.currentFile
        let arr = []
        if (currentPositionList && currentPositionList.length !== 0) {
          arr = currentPositionList.map(item => {
            // 如果有过拉伸则重新计算 x y 坐标
            if (item.changeFlag) {
              return {
                ...item,
                // x: parseInt(item.x / ratio, 10),
                // y: parseInt(item.y / ratio, 10),
              }
            }
            return item
          })
        } else {
          /* eslint-disable-next-line */
          arr = signData.signList.map(item => ({
            ...item,
            x: parseInt(item.x / ratio, 10),
            y: parseInt(item.y / ratio, 10),
          }))
        }
        let newArr
        newArr = await getMiddlePositionList(cloneDeep(arr))
        // 换算宽高
        newArr = reTranslateWidthAndHeightList(newArr)
        try {
          if (state.isNullifyFalg) {
            await Api.addCancelSignAndDate({
              signList: newArr,
              fileHtml: state.currentFile.fileHtml,
              subId,
              ...params, // 签署密码的参数
            })
          } else {
            await Api.addSignAndDate({
              signList: newArr,
              fileHtml: state.currentFile.fileHtml,
              subId,
              ...params, // 签署密码的参数
            })
          }
          if (params.success) {
            params.success()
          }
          if (params.onlyCheck) {
            return
          }
          if (params.cb) {
            await params.cb()
            return
          }
          commit('setShowSignPassword', false)
          await dispatch('getFileList', {
            subId: state.subId,
            isInit: false,
          })
        } catch (error) {
          console.log('error', error)
          commit('setErrMsg', error.msg)
          myApi.commonLog({
            fileLink: 'src/store/module/contractSign.js',
            params: {
              signList: newArr,
              fileHtml: state.currentFile.fileHtml,
              subId,
              ...params,
            }
          })
        }
        // return await Api.addSignAndDate({
        //   signList: arr,
        //   subId,
        //   ...params, // 签署密码的参数
        // }).then(async res => {
        //   if (params.onlyCheck) {
        //     return
        //   }
        //   if (params.cb) {
        //     params.cb()
        //     return
        //   }
        //   await dispatch('getFileList', {
        //     subId: state.subId,
        //     isInit: false,
        //   })
        // })
        // .finally(() => {
        //   commit('closeLoading', 'loading')
        // })
      } catch (error) {
        console.error(error)
      } finally {
        // commit('closeLoading', 'loading')
      }
    },
    // 审批合同
    approve({ commit, state, dispatch }, params = {}) {
      const { subId, approveResult } = state
      // commit('openLoading', 'loading')
      if (state.isNullifyFalg) {
        return Api.nullifyApprove({
          approveResult,
          subId,
          ...params, // 签署密码的参数
        })
          .then(async res => {
            commit('setShowSignPassword', false)
            if (params.onlyCheck) {
              return
            }
            if (params.cb) {
              params.cb()
              return
            }
            await dispatch('getFileList', {
              subId: state.subId,
              isInit: false,
            })
          })
          .finally(() => {
            // commit('closeLoading', 'loading')
          })
      }
      return Api.approve({
        approveResult,
        subId,
        ...params, // 签署密码的参数
      })
        .then(async res => {
          commit('setShowSignPassword', false)
          if (params.onlyCheck) {
            return
          }
          if (params.cb) {
            params.cb()
            return
          }
          await dispatch('getFileList', {
            subId: state.subId,
            isInit: false,
          })
        })
        .finally(() => {
          // commit('closeLoading', 'loading')
        })
    },
    // 获取文件列表
    async getFileList({ commit, state, dispatch }, { subId, isInit }) {
      try {
        commit('openLoading', 'fileLoading')
        let res
        console.log(111, state.isNullifyFalg)
        if (state.isNullifyFalg) {
          res = await Api.getInvalidFileList({
            subId,
          })
        } else {
          res = await Api.getFileList({
            subId,
          })
        }
        // 将原始的数据线保存一份，可以在修改后再还原
        res.forEach(file => {
          if (file.allVarList) {
            file.allVarList.forEach(item => {
              if (item.dataType === 3) {
                switch (item.dateDefaultType) {
                  case 1:
                    // varStatus=1 已经填写过了，就不需要替换为空了
                    if (!item.varStatus) {
                      item.varValue = ''
                    }
                    break
                  case 2:
                    item.varValue = formatDate(new Date(), item.dateFormat)
                    break
                  case 3:
                    break
                  default:
                    break
                }
              }
            })
          }
          if (file.varList) {
            file.varList.forEach(item => {
              if (item.dataType === 3) {
                switch (item.dateDefaultType) {
                  case 1:
                    // varStatus=1 已经填写过了，就不需要替换为空了
                    if (!item.varStatus) {
                      item.varValue = ''
                    }
                    break
                  case 2:
                    item.varValue = formatDate(new Date(), item.dateFormat)
                    break
                  case 3:
                    break
                  default:
                    break
                }
              }
            })
          }
          file.currentPositionList.forEach(currentPosition => {
            currentPosition.originalX = currentPosition.x
            currentPosition.originalY = currentPosition.y
            currentPosition.originalWidth = currentPosition.width
            currentPosition.originalHeight = currentPosition.height
            currentPosition.hasPosition = true

            const { signType } = currentPosition
            let defaultWidth
            let defaultHeight
            if (isUserSignType(signType)) {
              defaultWidth = userSignatureDefaultWidth * 2
              defaultHeight = userSignatureDefaultHeight * 2
            } else {
              defaultWidth = 165
              defaultHeight = 165
            }

            if (currentPosition.width === 0 || currentPosition.width == null) {
              currentPosition.width = defaultWidth
            }
            if (
              currentPosition.height === 0 ||
              currentPosition.height == null
            ) {
              currentPosition.height = defaultHeight
            }
          })
        })

        const currentIndex = state.currentFileIndex
        if (isInit) {
          const promiseList = await res.map(async item => {
            item.oldPositionList = await getStartPositionList(
              item.oldPositionList
            )
            return {
              ...item,
              signData: {
                signList: [],
                signPwd: null,
                subId: null,
                verifyCode: null,
                verifyType: 1, // 验证方式 1.短信验证码 2.签署密码
              },
            }
          })
          state.fileList = await Promise.all(promiseList)
        } else {
          // 不是第一次进入时，缓存用户填写的变量
          const PromiseList = await res.map(async (item, index) => {
            item.oldPositionList = await getStartPositionList(
              item.oldPositionList
            )
            if (index !== currentIndex) {
              return {
                ...item,
                currentPositionList: state.fileList[index].currentPositionList,
                signData: state.fileList[index].signData,
                varList: state.fileList[index].varList,
              }
            }
            return {
              ...item,
              signData: {
                signList: [],
                signPwd: null,
                subId: null,
                verifyCode: null,
                verifyType: 1, // 验证方式 1.短信验证码 2.签署密码
              },
            }
          })
          state.fileList = await Promise.all(PromiseList)
        }
        commit('setCurrentFile', state.fileList[currentIndex])
        // 默认进来就是获取第一个待处理的文件，如果没有待处理的文件了，就展示第一个
        dispatch('changeToFirstFileToBeProcess')
        // const firstFileToBeProcess = getFirstFileToBeProcess(state.fileList)
        // dispatch('changeFile', {
        //   fileData: firstFileToBeProcess || state.fileList[0],
        //   isChange: false,
        // })
      } catch (error) {
        console.error(error)
      } finally {
        commit('closeLoading', 'fileLoading')
      }
    },
    // 是否弹签署密码
    getSignStatus({ commit, state }) {
      let res
      if (state.isNullifyFalg) {
        res = Api.getInvalidSignStatus({
          subId: state.subId,
        })
      } else {
        res = Api.getSignStatus({
          subId: state.subId,
        })
      }
      return res
    },
    // 是否指定签章
    setAssignId({ commit }, params) {
      commit('setAssignId', params)
    },
  },
}
