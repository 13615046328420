import { get, post, qspost } from './axios'

const myApis = {
  /**
   * 上传文件(新)（File格式或者Base64格式文件）
   * 返回结果为{fileKey,fileUrl}
   * @param {*} param
   */
  uploadNew(param) {
    return post('/user/common/file/uploadNew', param)
  },
  // 查询前端替换字符串
  getChracter(param) {
    return get('/user/common/getChracter', param)
  },
}

export default myApis
