export default [
  {
    path: '/homepage',
    name: 'HomePage',
    component: () => import('@/views/HomePage/index'),
    meta: {
      title: '易云章',
      navShow: true,
      keepAlive: true,
    },
  },
]
